import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import authenticationReducer from './reducers/authentication/authentication';
import popupReducer from './reducers/popup/popup';
import googleLoaderReducer from './reducers/google/google';
import valueListReducer from './reducers/valueList/ValueList';
import pageContentReducer from './reducers/pageContent/pageContent';
import inboundLoad from './reducers/loadArea/inboundLoad';
import outboundLoad from './reducers/loadArea/outboundLoad';
import callCheckReducer from './reducers/callCheck/callCheck';
import deliveredTrucksReducer from './reducers/deliveredTrucksReducer/deliveredTrucks';
import trafficReducer from './reducers/trafficControl/trafficControl';
import trafficUpcomingReducer from './reducers/trafficControlUpcoming/trafficControlUpcoming';
import customerDashboardReducer from './reducers/customerDashboard/customerDashboard';
import customerOrderInProgressReducer from './reducers/customerOrderInProgress/customerOrderInProgress';
import customerOrderNewOrdersReducer from './reducers/customerOrderNewOrders/customerOrderNewOrders';
import customerOrderLocationsReducer from './reducers/customerOrderLocations/customerOrderLocations';
import customerRejectedOrdersReducer from './reducers/customerRejectedOrders/customerRejectedOrders';
import customerLastOrdersReducer from './reducers/customerLastOrders/customerLastOrders';
import customerOrderAdminReducer from './reducers/customerOrderAdmin/customerOrderAdmin';
import revisionOrderAdminReducer from './reducers/revisionOrderAdmin/revisionOrderAdmin';
import commentsReducer from './reducers/comments/comments';
import accountReceivableReducer from './reducers/accountReceivableReducer/accountReceivableColumns';
import loadDetailsReducer from './reducers/loadDetails/loadDetails';
import rateConfirmationReducer from './reducers/rateConfirmation/rateConfirmation';
import notesReducer from './reducers/notes/notes';
import deliveredLoadsReducer from './reducers/deliveredLoads/deliveredLoads';
import customersReducer from './reducers/customers/customers';
import ordersReducer from './reducers/orders/orders';
import factoringCompanyReducer from './reducers/factoringCompany/factoringCompany';
import locationsReducer from './reducers/locations/locations';
import areaGroupDetailsReducer from './reducers/areaGroupDetails/areaGroupDetails';

const comReducer = combineReducers({
  auth: authenticationReducer,
  popup: popupReducer,
  google: googleLoaderReducer,
  valueList: valueListReducer,
  pageContent: pageContentReducer,
  load: inboundLoad,
  outboundload: outboundLoad,
  callCheck: callCheckReducer,
  AccountReceivable: accountReceivableReducer,
  AccountPayable: deliveredTrucksReducer,
  traffic: trafficReducer,
  trafficUpcoming: trafficUpcomingReducer,
  customerDashboard: customerDashboardReducer,
  customerOrderInProgress: customerOrderInProgressReducer,
  customerOrderNewOrders: customerOrderNewOrdersReducer,
  customerOrderLocations: customerOrderLocationsReducer,
  customerRejectedOrders: customerRejectedOrdersReducer,
  customerLastOrders: customerLastOrdersReducer,
  customerOrderAdmin: customerOrderAdminReducer,
  revisionOrderAdmin: revisionOrderAdminReducer,
  comments: commentsReducer,
  loadDetails: loadDetailsReducer,
  rateConfirmation: rateConfirmationReducer,
  notes: notesReducer,
  deliveredLoads: deliveredLoadsReducer,
  customers: customersReducer,
  orders: ordersReducer,
  factoringCompany: factoringCompanyReducer,
  locations: locationsReducer,
  areaGroup: areaGroupDetailsReducer,
});
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(comReducer, composeEnhancers(applyMiddleware(thunk)));
export default store;
