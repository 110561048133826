import axios from 'axios';
import store from '../..';
import * as actionTypes from './areaGroupDetailsActionTypes';

const buildUrl = (resource, id) => (id ? `/api/${resource}/${id}` : `/api/${resource}`);

const axiosConfig = () => ({
  headers: {
    Authorization: 'Bearer ' + store.getState().auth.userToken,
  },
});

export const getAreaGroupAllLocationsList = (resource, url) => dispatch => {
  // dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_AREA_GROUP_DETAILS_BY_ID_LOADING) });
  axios
    .get(buildUrl(resource, url), axiosConfig())
    .then(response => dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_AREA_GROUP_ALL_LOCATIONS_LIST), data: response }))
    .catch(err => {
      console.error(err);
      console.log(err.response);
      dispatch({ type: actionTypes.getActionType(resource, actionTypes.ERROR) });
    });
};

export const getAreaGroupDetailsById = (resource, url, AreaGroupId) => dispatch => {
  const data = {
    params: {
      filterData: { AreaGroupId: AreaGroupId },
    },
    headers: axiosConfig().headers,
  };
  // dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_AREA_GROUP_DETAILS_BY_ID_LOADING) });
  axios
    .get(buildUrl(resource, url), data, axiosConfig())
    .then(response => dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_AREA_GROUP_DETAILS_BY_ID), data: response }))
    .catch(err => {
      console.error(err);
      console.log(err.response);
      dispatch({ type: actionTypes.getActionType(resource, actionTypes.ERROR) });
    });
};

export const getAreaGroupList = (resource, url, name) => dispatch => {
  const data = {
    params: {
      filterData: { name: name },
    },
    headers: axiosConfig().headers,
  };
  dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_AREA_GROUP_DETAILS_LIST_LOADING) });
  axios
    .get(buildUrl(resource, url), data, axiosConfig())
    .then(response => dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_AREA_GROUP_DETAILS_LIST), data: response }))
    .catch(err => {
      console.error(err);
      console.log(err.response);
      dispatch({ type: actionTypes.getActionType(resource, actionTypes.ERROR) });
    });
};

export const getAreaGroupDetails = (resource, id) => dispatch => {
  dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_AREA_GROUP_DETAILS_DETAILS_LOADING) });
  axios
    .get(buildUrl(resource, id), axiosConfig())
    .then(response => dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_AREA_GROUP_DETAILS_DETAILS), data: response }))
    .catch(err => {
      console.error(err);
      console.log(err.response);
      dispatch({ type: actionTypes.getActionType(resource, actionTypes.ERROR) });
    });
};

export const getLocationsByState = (resource, url) => dispatch => {
  // dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_LOCATIONS_LIST_BY_STATE_IDS_LOADING) });
  axios
    .get(buildUrl(resource, url), axiosConfig())
    .then(response => dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_LOCATIONS_LIST_BY_STATE_IDS), data: response }))
    .catch(err => {
      console.error(err);
      console.log(err.response);
      dispatch({ type: actionTypes.getActionType(resource, actionTypes.ERROR) });
    });
};

export const addAreaGroupDetails = (resource, url, values, submitActinType) => dispatch => {
  // dispatch({ type: actionTypes.getActionType(resource, actionTypes.ADD_AREA_GROUP_DETAILS_LOADING) });
  const data = {
      areaGroupId: Number(values.areaGroupId),
      locationIds: values.locationIds, 
  };
  axios
    .post(buildUrl(resource, url), data, axiosConfig())
    .then(response => {
      if (response.data.hasError) {
        dispatch({ type: actionTypes.getActionType(resource, actionTypes.ERROR), data: response.data.message });
      } else {
        dispatch({ type: actionTypes.getActionType(resource, actionTypes.ADD_AREA_GROUP_DETAILS), data: { response, submitActinType } });
        dispatch(reloadAfterUpdate(resource, Number(values.areaGroupId)));
      }
    })
    .catch(err => {
      console.error(err);
      console.log(err.response);
      dispatch({ type: actionTypes.getActionType(resource, actionTypes.ERROR) });
    });
};


export const getStatesListByCountryId = (resource, id) => dispatch => {
  axios
    .get(buildUrl(resource, id), axiosConfig())
    .then(response => dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_STATES_LIST), data: response }))
    .catch(err => {
      console.error(err);
      console.log(err.response);
      dispatch({ type: actionTypes.getActionType(resource, actionTypes.ERROR) });
    });
};


export const emptyAllLocations = (resource) => ({
  type: actionTypes.getActionType(resource, actionTypes.EMPTY_ALL_LOCATIONS)
});

export const resetError = resource => ({ type: actionTypes.getActionType(resource, actionTypes.RESET_ERROR) });

const reloadAfterUpdate = (resource, AreaGroupId) => dispatch => {
  const data = {
    params: {
      filterData: { AreaGroupId: AreaGroupId },
    },
    headers: axiosConfig().headers,
  };
  axios
    .get(buildUrl(resource, 'locationgrouplist'), data, axiosConfig())
    .then(response => dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_AREA_GROUP_DETAILS_BY_ID), data: response }))
    .catch(err => {
      console.error(err);
      console.log(err.response);
      dispatch({ type: actionTypes.getActionType(resource, actionTypes.ERROR) });
    });
};
