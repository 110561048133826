import { useSelector } from "react-redux";

export const AreaGroupDetailsSelectors = () => {
  const state = useSelector(state => state);
  const loading = useSelector(state => state.areaGroup.loading);
  const areaGroupList = useSelector(state => state.areaGroup.areaGroupList);
  const areaGroupDetailsList = useSelector(state => state.areaGroup.areaGroupDetails);
  const areaGroupDetails = useSelector(state => state.areaGroup.areaGroupDetails);
  const sortType = useSelector(state => state.areaGroup.sortType);
  const messageText = useSelector(state => state.areaGroup.messageText);
  const hasError = useSelector(state => state.areaGroup.hasError);
  const newAreaGroupAdded = useSelector(state => state.areaGroup.newAreaGroupAdded);
  const areaGroupUpdated = useSelector(state => state.areaGroup.areaGroupUpdated);
  const submitActinType = useSelector(state => state.areaGroup.submitActinType);
  const statesList = useSelector(state => state.areaGroup.statesList);
  const stateLocationsList = useSelector(state => state.areaGroup.stateLocationsList);
  const areaGroupDetailsById = useSelector(state => state.areaGroup.areaGroupDetailsById);
  const areaGroupAllLocationsList = useSelector(state => state.areaGroup.areaGroupAllLocationsList)

  return { 
    state, 
    loading,
    areaGroupList, 
    areaGroupDetails, 
    sortType, 
    messageText, 
    hasError, 
    newAreaGroupAdded,
    areaGroupUpdated,
    submitActinType,
    statesList,
    areaGroupDetailsList,
    stateLocationsList,
    areaGroupDetailsById,
    areaGroupAllLocationsList,
  };
};