import React, { useState } from 'react';
import { Input, Checkbox } from 'antd';

const Search = ({ setSearchInput, setFilterInput, searchInput }) => {
    const [checkboxSelected, setCheckboxSelected] = useState(true);

    const handleCheckboxSelected = () => {
        setCheckboxSelected(!checkboxSelected);
        setFilterInput('');
        setSearchInput('');
    };

    return (       
        <div style={{ display: 'flex', marginLeft: '24px'}}>
            <Checkbox checked={checkboxSelected} onChange={() => handleCheckboxSelected()} />
            {checkboxSelected ? <>
                <div className='search_title'>Search</div>
                <Input
                    className='load__input_search'
                    onChange={e => setSearchInput(e.target.value.toLowerCase())}
                    value={searchInput}
                    placeholder="PO#"
                    type="text"
                /> 
            </> : null}
            {!checkboxSelected ? <>
                <div className='search_title'>Filter</div>
                <Input
                    className='load__input_search'
                    onChange={e => setFilterInput(e.target.value.toLowerCase())}
                    placeholder="PO#"
                    type="text"
                /> 
            </> : null}
        </div>
    );
};

export default Search;