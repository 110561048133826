import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from "react-redux";
import moment from 'moment'
import { Defines } from '../../../../project/Defines';
import { DatePicker, InputNumber, Select, Tooltip, message } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import CurrencyInput from 'react-currency-input-field';
import { setItemData, setItemDisplayState } from '../../../../store/actions/loadArea/loadArea';
import { GenerateMessages } from '../../../helper/element';
import './index.css';
import LoadDetailsModal from './loadDetailsModal';
import AppointmentSelector from './appointmentSelector';
import HistoryModal from './HistoryModal';

const Cell = ({
        state,
        cellData,
        itemSize,
        startOnScroll,
        rowState,
        setItemData,
        setItemDisplayState,
        resource,
        reacted,
        delOrPickNumberColor,
        selectedOrder,
        orderDropLocationIsDisabled,
        borderColor,
    }) => {
    const location = useLocation();
    const loadArea = state[resource];
    const redonly = 1;
    const editable = 2;
    const header = loadArea.headers.find(h => h.key === cellData.key);
    const selectNameOptionsPalletTypes = [{ name: 'Straight', id: 1 }, { name: 'Chimney', id: 2 }, { name: 'Other', id: 3 }, { name: 'Sideways', id: 4 }];
    const selectOptionsPalletTypes = { 1: 'Straight', 2: 'Chimney', 3: 'Other', 4: 'Sideways' };
    const selectNameOption = [{ name: 'Open', id: 2 }, { name: 'Pending', id: 3 }, { name: 'Confirmed', id: 4 }, { name: 'Change', id: 1 } ];
    const selectOption = { 2: 'Open', 3: 'Pending', 4: 'Confirmed', 1: 'Change' };

    const locations = state?.locations?.locations;
    const weekArray = Array.from(Array(moment().weeksInYear()), (_, x) => x+1);
    const weekNow = new Date().getWeek()
    const spliceWeekList = weekArray.splice(weekArray.indexOf(weekNow))
    const weekList = spliceWeekList.map((item,index) => ({ "value": `${item }`, "text": `${item }` }))
    const isSub = rowState.isSub;
    const orderHasSub = loadArea.subOrders?.find(sub => sub?.orderId === rowState?.orderData?.id);
    const isOrder = rowState.type === 'order';

    let data = null;
    let secondaryData = null;
    let thirdData = null;
    let forthData = null;
    let fifthData = null;
    let sixthData = null;
    if (Array.isArray(cellData.value)) {
        data = cellData.value.length > 0 ? cellData.value[0] : null;
        secondaryData = cellData.value.length > 1 ? cellData.value[1] : null;
        thirdData = cellData.value.length > 2 ? cellData.value[2] : null;
        forthData = cellData.value.length > 3 ? cellData.value[3] : null;
        fifthData = cellData.value.length > 4 ? cellData.value[4] : null;
        sixthData = cellData.value.length > 5 ? cellData.value[5] : null;
    } else {
        data = cellData.value;
    };
    
    const cellStyle = cellData.cellStyle;
    const tooltip = cellData.tooltip;
    const secondValue = cellData.secondValue;
    const thirdValue = cellData.thirdValue;

    const width = header?.width;
    const lastSelectId = loadArea.lastSelected.id;
    const lastSelectHeaderKey = loadArea.lastSelected.headerKey;
    const lastSelectenable = loadArea.lastSelected.enable;
    const rowStateId = rowState.id;
    const headerKey = header?.key;

    const [isDetailsModalOpen, setDetailsModalToOpen] = useState(false);
    const [isHistoryModalOpen, setHistoryModalOpen] = useState(false);
    const [displayState, setDisplayState] = useState(redonly);
    const [tempData, setTempData] = useState(null);
    const [valueData, setValueData] = useState(null);
    const [updatedValue, setUpdatedValue] = useState(thirdValue);

    useEffect(() => {
        if (lastSelectId === rowStateId && lastSelectHeaderKey === headerKey && lastSelectenable) {
            setDisplayState(editable);
        }
    }, [lastSelectId, lastSelectHeaderKey, lastSelectenable, rowStateId, headerKey]);

    useEffect(() => {
        if ((headerKey === 'customerCodeOrName') && cellData.editable) {
            setDisplayState(editable);
        }
    }, [cellData.editable, headerKey]);

    useEffect(() => {
        setTempData(data);
        setValueData(data);
    }, [data]);

    useEffect(() => {
        if (inputReference && inputReference.current) {
            inputReference.current.focus();
        }
    }, [displayState]);

    const inputReference = useRef(null);

    const rowSelectApptsColorClass = status => {        
        switch (Number(status)) {
            case 2: return 'open_style';
            case 1: return 'change_style';
            case 3: return 'pending_style';
            case 4: return 'confirmed_style';
            default: return '';
        }
    };

    const dataCompare = (newData, data) => {
        switch (cellData.fieldType) {
            case 'date':
            case 'dateTime':
            case 'appointmentSelector':
                if (newData instanceof Date && data instanceof Date) {
                    return newData.getTime() === data.getTime();
                }
                return newData === data;
            case 'integer':
                return Number(newData) === Number(data);
            default:
                return newData === data;
        }
    }

    
    const throwNeededMessage = (headerKey) => {
        if (headerKey === 'cases' || headerKey === 'space') {
            message.error("Cannot exceed original count");
        }
        if (headerKey === 'weight') {
            message.error("Exceeded original limit");
        }
        if (headerKey === 'palletCount') {
            message.error("Exceeds original count");
        }
    }

    const setData = (value, h, ft) => {
        if (header.key === 'cases' && value < 0) {
            message.error("The case can't be a negative value");
            return
        }
        if ((h === 'palletCount' || h === 'case' || h === 'space' || h === 'weight') && !dataCompare(value, tempData) && !isSub) {
            setUpdatedValue(true);
        }
        if (Array.isArray(value)) {
            onSetBlur();
            setTimeout(() => {
                setItemData(resource, value, rowState);
            }, 100);
        } else if (value === 'unColor' && !isSub) {
            setUpdatedValue(false);
            onSetBlur();
            setTimeout(() => {
                setItemData(resource, 'unColor', rowState, h);
            }, 100);
        } else {
            h = h === undefined ? header : loadArea.allHeaders.find(hed => hed.key === h);
            ft = ft === undefined ? cellData.fieldType : ft;
            onSetBlur();
            let newValue = value;
            if (value !== '') {
                switch (ft) {
                    case 'date':
                        newValue = new Date(value);
                        break;
                    case 'dateTime':
                    case 'appointmentSelector':
                        newValue = new Date(value);
                        break;
                    case 'text':
                        newValue = value;
                        break;
                    case 'textInput':
                        newValue = value;
                        break;
                    case 'integer':
                        newValue = value;
                        break;
                    case 'select':
                        newValue = value;
                        break;
                    case 'selectManifest':
                        newValue = value;
                        break;
                    case 'decimal':
                        newValue = value;
                        break;
                    case 'currency':
                        newValue = value;
                        break;
                    default:
                        newValue = value;
                        break;
                }
            }
            if (!dataCompare(newValue, tempData)) {
                setTempData(newValue);  
                setTimeout(() => {
                    setItemData(resource, newValue, rowState, h);
                }, 100);
            }
        }
    };

    let rowSelectAppts_color__class = 
    tempData === 1 && (header.key === 'inboundAppointmentStatus' || header.key === 'outboundAppointmentStatus') ? 'change_style' : 
    tempData === 2 && (header.key === 'inboundAppointmentStatus' || header.key === 'outboundAppointmentStatus') ? 'open_style': 
    tempData === 3 && (header.key === 'inboundAppointmentStatus' || header.key === 'outboundAppointmentStatus') ? 'pending_style' :
    tempData === 4 && (header.key === 'inboundAppointmentStatus' || header.key === 'outboundAppointmentStatus') ? 'confirmed_style' : '';

    const isSubClass = rowState.isSub && rowState.type === 'order' ? 'isSub' : '';
    const isSubClassAndReacted = rowState.isSub && reacted && (header.key === 'inboundAppointmentStatus' || header.key === 'outboundAppointmentStatus') ? 'isSubReacted' : '';

    if (reacted && (cellData.key === 'outboundAppointmentStatus' || cellData.key === 'inboundAppointmentStatus')) {
        // rowSelectAppts_color__class = 'reacted';
    };

    let nummber = '';
    if (resource === 'load') {
        if (cellData.key === 'inboundPickAppointment') {
            nummber = rowState?.orderData?.inPickNummber;
        } else {
            nummber = rowState?.orderData?.inDeliverNummber;
        }
    } else {
        if (cellData.key === 'outboundDropAppointment') {
            nummber = rowState?.orderData?.outDeliverNummber;
        } else {
            nummber = rowState?.orderData?.outPickNummber;
        }
    }

    const subOrder = loadArea?.subOrders?.filter(subOrder => subOrder.orderId === rowState.orderObjectId)[0];
    let showRemoveIcon = true;
    if (rowState?.subId && rowState.type === 'order' && subOrder?.pickAppointmentDate && header.key === 'outboundPickAppointment') {
        showRemoveIcon = false;
    } else if (rowState?.subId && rowState.type === 'order' && subOrder?.dropAppointmentDate && header.key === 'outboundDropAppointment') {
        showRemoveIcon = false;
    }
        
    const subOrder_OrderId = rowState?.isSub && rowState.type === 'order' && rowState?.orderObjectId;
    let mainOrder = '';
    let subIsDisabled = false;
    if (subOrder_OrderId) {
        mainOrder = loadArea?.loadsAssignedOrders?.filter(order => order?.rowState?.orderData?.id === subOrder_OrderId && order?.rowState?.subId)?.[0];
        if (!mainOrder?.rowState?.orderData?.outboundPickAppointment && header.key === 'outboundPickAppointment') {
            subIsDisabled = true;
        } else if (!mainOrder?.rowState?.orderData?.outboundDropAppointment && header.key === 'outboundDropAppointment') {
            subIsDisabled = true;
        }
    }

    const tooltipForLocation = (workingDaysStr, inContactTypeDetails) => {
        const updatedWorkingDays = workingDaysStr?.split(',');
        let workingDaysObject = {};
        const workingDaysNames = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
        for (let i = 0; i < updatedWorkingDays?.length; i++) {
            let day = workingDaysNames[i];
            workingDaysObject[day] = updatedWorkingDays[i].trim();
        }

        let tooltipData = workingDaysStr ? <div>
            <div>Working hours</div>
            <div>Monday: {workingDaysObject.Monday}</div>
            <div>Tuesday: {workingDaysObject.Tuesday}</div>
            <div>Wednesday: {workingDaysObject.Wednesday}</div>
            <div>Thursday: {workingDaysObject.Thursday}</div>
            <div>Friday: {workingDaysObject.Friday}</div>
            <div>Saturday: {workingDaysObject.Saturday}</div>
            <div>Sunday: {workingDaysObject.Sunday}</div>
        </div> : null;
        if ((workingDaysObject.Monday === workingDaysObject.Tuesday &&
            workingDaysObject.Monday === workingDaysObject.Wednesday &&
            workingDaysObject.Monday === workingDaysObject.Thursday &&
            workingDaysObject.Monday === workingDaysObject.Friday) &&
            (workingDaysObject.Saturday === workingDaysObject.Sunday)        
        ) {
            tooltipData = workingDaysStr ? <div>
                <div>Working hours</div>
                <div>Monday-Friday: {workingDaysObject.Monday}</div>
                <div>Saturday-Sunday: {workingDaysObject.Saturday}</div>
            </div> : null;
        };

        const contactDetails = inContactTypeDetails ? <div>Contact: {inContactTypeDetails}</div> : null;
        const finalData = (tooltipData || contactDetails) &&
            <div className='tooltip__data'>
                {tooltipData}
                <br/>
                {contactDetails}
            </div>

        return finalData;
    }

    const getNameById = tempData => {
        return locations?.length && locations?.find(loc => loc.value === tempData)?.text;
    }

    const renderLoadNumber = () => {
        const loadId = rowState.loadData?.id;
        const onlyOrders = state[resource].loadsAssignedOrders.filter(order => !order.ordersData);
        const allOrdersForCurrentLoad = onlyOrders.filter(load => load.rowState.loadData?.id === loadId);
        
        let minOneOrderIsUpdatedFromLoad;
        if (resource === 'load') {
            allOrdersForCurrentLoad.forEach(order => {
                if (order.rowState.orderData?.orderHistoryDict?.inDropApp || 
                    order.rowState.orderData?.orderHistoryDict?.inPickApp || 
                    order.rowState.orderData?.orderHistoryDict?.outDropApp || 
                    order.rowState.orderData?.orderHistoryDict?.outPickApp ||
                    order.rowState.orderData?.orderHistoryDict?.subloadNewlyCreated
                ) {
                    minOneOrderIsUpdatedFromLoad = true;
                }
            })
        } else if (resource === 'outboundload') {
            allOrdersForCurrentLoad.forEach(order => {
                if (order.rowState.orderData?.outOrderHistoryDict?.inDropApp || 
                    order.rowState.orderData?.outOrderHistoryDict?.inPickApp || 
                    order.rowState.orderData?.outOrderHistoryDict?.outDropApp || 
                    order.rowState.orderData?.outOrderHistoryDict?.outPickApp ||
                    order.rowState.orderData?.outOrderHistoryDict?.subloadNewlyCreated
                ) {
                    minOneOrderIsUpdatedFromLoad = true;
                }
            })
        }

        const isRateConfirmed = secondValue;
        const carrierInformed = thirdValue;

        const updatedFields = resource === 'load' ? 
        rowState?.orderData?.orderHistoryDict && 
        Object.entries(rowState?.orderData?.orderHistoryDict).filter(([key, value]) => value).map(([key, value]) => key) :
        rowState?.orderData?.outOrderHistoryDict && 
        Object.entries(rowState?.orderData?.outOrderHistoryDict).filter(([key, value]) => value).map(([key, value]) => key);
    
        let dataIsUpdated = false;
        let hoverText = [];

        updatedFields && updatedFields.forEach(field => {
            if (field === 'inDropApp' || field === 'inPickApp' || field === 'outDropApp' || field === 'outPickApp') {
                if (!hoverText?.includes('Appointment date was changed')) {
                    hoverText.push('Appointment date was changed')
                    dataIsUpdated = true;
                } else if (field === 'subloadNewlyCreated') {
                    dataIsUpdated = true;
                }
            }
        })

        let whiteBG = '';
        if (isRateConfirmed && carrierInformed) {
            whiteBG = true;
        } else if (!isRateConfirmed) {
            whiteBG = false;
        } else { 
            if (isRateConfirmed && !carrierInformed && dataIsUpdated) {
                whiteBG = false;
            } else if (isRateConfirmed && !carrierInformed && minOneOrderIsUpdatedFromLoad) {
                whiteBG = false;
            } else {
                whiteBG = true;
            }         
        }

        if (!isRateConfirmed) {
            if (!hoverText.includes('Rate Confirmation is absent')) {
                hoverText.push('Rate Confirmation is absent')
            }
        }
        

        const renderTooltip = hoverText => {
            return hoverText.map(item => <div>{item}</div>)
        }

        if (hoverText.length && !whiteBG) {
            return <Tooltip title={hoverText ? renderTooltip(hoverText): ''}>
                        <div style={{ background: whiteBG ? '': '#f4db5b', height: '18px' }}>{tempData}</div>
                    </Tooltip>
        } else {
            return <div style={{ background: whiteBG ? '': '#f4db5b', height: '18px' }}>{tempData}</div>
        }
    }

    const getData = () => {
        if (tempData !== '') {
            switch (cellData.fieldType) {
                case 'date':
                    if (cellData.key === 'dueDate') {
                        const indicator = rowState?.orderData?.orderIndicator;
                        if (rowState?.orderData?.isHardDate) {
                            return (
                                <Tooltip title='Hard Date'>
                                <span style={{ color: 'rgb(96, 0, 128)' }}>{tempData ? moment(tempData).format(Defines.Format.PrintDate) : ''}</span>
                                </Tooltip>
                            )
                        } else if (indicator === 2) {
                            return (
                                <Tooltip title='Data Change'>
                                <span style={{ color: '#EF2112' }}>{tempData ? moment(tempData).format(Defines.Format.PrintDate) : ''}</span>
                                </Tooltip>
                            )
                        } else {
                            return (
                                <Tooltip title='Standard'>
                                <span style={{ color: '#0000FF' }}>{tempData ? moment(tempData).format(Defines.Format.PrintDate) : ''}</span>
                                </Tooltip>
                            )
                        }
                    } else {
                        return <>{tempData ? moment(tempData).format(Defines.Format.PrintDate) : ''}</>;
                    }
                case 'appointmentSelector':
                    return <Tooltip title={nummber}>
                            <div className={`date-time__container ${rowSelectApptsColorClass(Number(secondaryData))}`}>
                                <div className='date__part'>{tempData ? moment(tempData).format(Defines.Format.Date) : ''}</div>
                                <div className='time__part'>{tempData ? moment(tempData).format(Defines.Format.Time) : ''}</div>
                            </div>
                        </Tooltip>;
                case 'dateTime':
                    if (header.key === 'inboundPickIn' ||
                        header.key === 'inboundPickOut' ||
                        header.key === 'outboundPickIn' ||
                        header.key === 'outboundPickOut' ||
                        header.key === 'inboundPickAppointment' ||
                        header.key === 'inboundDropAppointment' ||
                        header.key === 'outboundPickAppointment' ||
                        header.key === 'outboundDropAppointment' ||
                        header.key === 'inboundDropIn' ||
                        header.key === 'inboundDropOut' ||
                        header.key === 'outboundDropIn' ||
                        header.key === 'outboundDropOut' ||
                        header.key === 'inboundDeliveryAPPT')
                    {
                        return (<div className='date-time__container'>
                            <div className='date__part'>{tempData ? moment(tempData).format(Defines.Format.Date) : ''}</div>
                            <div className='time__part'>{tempData ? moment(tempData).format(Defines.Format.Time) : ''}</div>
                        </div>);
                    }
                    else
                    {
                        return tempData ? moment(tempData).format(Defines.Format.PrintDateTime) : '';
                    }
                case 'text':
                    if (header.key === 'soNumber' && tempData) {
                        const withTL = tempData?.slice(0, 2) === 'TL';
                        if (withTL) {
                            return <div style={{color: 'red'}}>{tempData}</div>
                        }
                    }
                    if (header.key === 'notes') {
                        return <span className='notes-number-clickable' onClick={setDetailsModalToOpen}>{tempData}</span>
                    }
                    let colorClass = '';
                    if (((header.key === 'palletCount' || header.key === 'space') && tempData > 30) ||
                        (header.key === 'weight' && tempData > 40000)) {
                        colorClass = 'dark-purple'
                    }
                    if ((header.key === 'palletCount' || 
                        header.key === 'space' || 
                        header.key === 'cases' || 
                        header.key === 'weight') && !!Number(secondValue)) {
                        return cellData.isBold ? <b>{tempData}</b> :
                            <span className={colorClass}>
                                {`${Number(tempData) - Number(secondValue)}/${tempData}`}
                            </span>;
                    }
                    if (header.key === 'pickLocation' && resource === 'outboundload' && cellData.isBold) {
                        return <Tooltip title={'Direct'}>
                            <b>{tempData}</b>
                        </Tooltip>
                    }
                    if (header.key === 'pickLocation') {
                        const contactData = resource === 'load' ? rowState?.orderData?.inContactTypeDetails : ''
                        const tooltipData = tooltipForLocation(rowState?.orderData?.pickWorkingDaysStr, contactData);
                        return <Tooltip overlayStyle={{ whiteSpace: 'pre-line' }} title={tooltipData}>
                            <div style={{ position: 'relative'}}>
                                {tempData}
                            </div> 
                        </Tooltip>
                    }
                    if (header.key === 'dropLocation') {
                        const contactData = resource === 'load' && !rowState?.orderData?.loadId ? '' : rowState?.orderData?.outContactTypeDetails;
                        const tooltipData = tooltipForLocation(rowState?.orderData?.dropWorkingDaysStr, contactData);
                        return <Tooltip overlayStyle={{ whiteSpace: 'pre-line' }} title={tooltipData}>
                            <div style={{ position: 'relative'}}>
                                {tempData}
                                {(orderHasSub && !isSub && isOrder) || (!isSub && isOrder && orderDropLocationIsDisabled) ? 
                                    <DownOutlined 
                                        className='select-arrow'
                                        onClick={() => message.error("It’s impossible to update the order location, as the order has an outbound suborder")} 
                                    /> : ''}
                            </div> 
                        </Tooltip>
                    }
                    if (header.key === 'loadNumber' && rowState.type === 'order' && rowState.loadId) {
                        return renderLoadNumber();
                    }
                    return cellData.isBold ? <b>{tempData}</b> : tempData;
                case 'textInput':
                    let BGColorPOTBD = '';
                    if (selectedOrder && selectedOrder[0] && rowState?.orderObjectId === selectedOrder[0].rowState?.orderObjectId) {
                        BGColorPOTBD = 'lightGray';
                    }
                    return cellData.isBold ? <b>{tempData}</b> : <div style={{ background: BGColorPOTBD }}>{tempData}</div>
                case 'textInputPO':
                    let colorPO = '';
                    let BGColorPO = '';
                    let fontWeightPO = 'inherit';
                    if (Number(rowState?.orderData.palletCount) !== Number(rowState?.orderData.space)) {
                        colorPO = '#DA2FC9';
                        fontWeightPO = 'bold';
                    }            
                    if (selectedOrder && selectedOrder[0] && rowState?.orderObjectId === selectedOrder[0].rowState?.orderObjectId) {
                        BGColorPO = 'lightGray'
                    }
                    let asterisks = '';
                    for(let i = 0; i < secondValue; i++) {
                        asterisks += '*';
                    }
                    return <div style={{ display: 'flex'}}>
                        <input
                            defaultValue={tempData ? tempData : ''}
                            className={`disabled__input ${secondValue ? 'po__data' : ''} `}
                            style={{ color: colorPO, background: BGColorPO, fontWeight: fontWeightPO }}
                            disabled
                        />
                        {secondValue && <input
                            defaultValue={secondValue ? asterisks : ''}
                            className='disabled__input asterisk'
                            style={{ color: colorPO, background: BGColorPO, fontWeight: fontWeightPO }}
                            disabled
                        />}
                    </div>        
                case 'integer':
                    if ((header.key === 'palletCount' ||
                        header.key === 'space' ||
                        header.key === 'cases' ||
                        header.key === 'weight') && secondValue) {
                        return cellData.isBold ? 
                        <b>{tempData}</b> : 
                            secondValue && tempData ? 
                            <span onClick={() => message.error('Only the latest subload can be edited', 5)}>
                                {`${tempData - secondValue}/${tempData}`}
                            </span> : 
                            tempData ;
                    
                    }
                    if ((header.key === 'palletCount' ||
                        header.key === 'space' ||
                        header.key === 'cases' ||
                        header.key === 'weight') && updatedValue === true) {
                        return <span onDoubleClick={() => handleUnColor()} className='changed-value__BG'>{tempData}</span>;
                    }
                    return cellData.isBold ? <b>{tempData}</b> : tempData;
                case 'currency':
                    const dollarUSLocale = Intl.NumberFormat('en-US');
                    let formatedData = '';
                    if (tempData) {
                        formatedData = dollarUSLocale.format(tempData);
                    }
                    else {
                        return '';
                    }
                    return cellData.isBold ? <b>{'$' + formatedData}</b> : formatedData === null ? formatedData : formatedData === undefined ? formatedData : '$' + formatedData;
                case 'decimal':
                    return cellData.isBold ? <b>parseFloat(tempData).toFixed(2)</b> : parseFloat(tempData).toFixed(2);
                case 'select':
                        let selectOptions;
                        let selectNameOptions;
                        if (header.key === 'pickRestriction' || header.key === 'dropRestriction') {
                            selectOptions = selectOptionsPalletTypes;
                            selectNameOptions = selectNameOptionsPalletTypes;
                        } else {
                            selectOptions = selectOption;
                            selectNameOptions = selectNameOption;
                        }
                        return <Select 
                            value={selectOptions[`${tempData}`]}
                            className={`rowSelect ${isSubClass} ${isSubClassAndReacted}
                                        ${header.key === 'inboundAppointmentStatus' || header.key === 'outboundAppointmentStatus' ? 
                                        `${rowSelectAppts_color__class}`: ''}
                                        `}
                            onChange={handleSelectChange}
                            defaultValue={selectOptions[`${tempData}`]}
                            >
                            {selectNameOptions.map((item, index) => {
                                return <Select.Option 
                                        key={index} 
                                        value={item.id} 
                                        className={`${
                                            item.id === 2 && (header.key === 'inboundAppointmentStatus' || 
                                                            header.key === 'outboundAppointmentStatus') ? 'open_style' :
                                            item.id === 1 && (header.key === 'inboundAppointmentStatus' || 
                                                            header.key === 'outboundAppointmentStatus') ? 'change_style' :
                                            item.id === 3 && (header.key === 'inboundAppointmentStatus' || 
                                                            header.key === 'outboundAppointmentStatus') ? 'pending_style' :
                                            item.id === 4 && (header.key === 'inboundAppointmentStatus' || 
                                                            header.key === 'outboundAppointmentStatus') ? 'confirmed_style' :
                                            ''} `}>
                                        {item.name}
                                    </Select.Option>
                            })}
                        </Select>;
                case 'selectLocations':
                    let tooltipData;
                    if (header.key === 'dropLocation') {
                        const contactData = resource === 'outboundload' ? rowState?.orderData?.outContactTypeDetails : '';
                        tooltipData = tooltipForLocation(rowState?.orderData?.dropWorkingDaysStr, contactData);
                    }
                    return <Tooltip title={tooltipData}>
                        <Select 
                            value={getNameById(tempData)}
                            className='rowSelect'
                            onChange={handleSelectChange}
                            defaultValue={getNameById(tempData)}>
                            {locations?.map((item, index) => {
                                return <Select.Option 
                                        key={index} 
                                        value={item.value}>
                                        {item.text}
                                    </Select.Option>
                            })}
                        </Select>
                    </Tooltip>
                case 'selectManifest':
                    return cellData.isBold ? <b>{tempData}</b> : tempData;
                default:
                    return cellData.isBold ? <b>{tempData}</b> : tempData;
            }
        }
        if (tempData === '' && nummber && 
                (cellData.key === 'inboundPickAppointment' || 
                    cellData.key === 'outboundPickAppointment' ||
                    cellData.key === 'inboundDropAppointment' ||
                    cellData.key === 'outboundDropAppointment'
                ) ) {
            return (
                <Tooltip title={nummber}>
                    <div style={{visibility: 'hidden'}}>Hidden Text</div>
                    <div style={{visibility: 'hidden'}}>Hidden Text</div>
                </Tooltip>)
        }
        return tempData
    };

    const handleSelectChange = (value) => {
        setData(parseInt(value));
    };
    const handleSelectChangeManifest = (value) => {
        setData(parseInt(value));
    };

    const handleUnColor = () => {
        if (!isSub) {
            setUpdatedValue(false);
            setTimeout(() => {
                setData('unColor', header);
            }, 100);
        }
    };

    const getInput = () => {
        switch (cellData.fieldType) {
            case 'appointmentSelector':
                return <AppointmentSelector
                    open={displayState === editable}
                    inputRef={inputReference}
                    currentDateValue={tempData ? moment(tempData, Defines.Format.PrintDateTime) : ''}
                    currentStatusValue={secondaryData}
                    currentPickOrDelNumber={thirdData}
                    lastUpdatedData={forthData}
                    lastUpdateAuthor={sixthData}
                    setData={setData}
                    resource={resource}
                    cellDataKey={cellData.key}
                    rowState={rowState}
                    onBlur={() => {
                        setTimeout(() => {
                            onSetBlur();
                            if (inputReference.current) {
                                setDisplayState(redonly);
                            }
                        }, 0);
                    }}
                    onOk={(dateVal, statusValue, currentPickOrDelNumber) => {
                        if (inputReference.current) {
                            setDisplayState(redonly);
                        }
                        let newData = [];
                        if (resource === 'load') {
                            if (cellData.key === 'inboundPickAppointment') {
                                newData.push(
                                    {
                                        value: statusValue,
                                        header: "inboundAppointmentStatus",
                                    }, {
                                        value: currentPickOrDelNumber,
                                        header: "inPickNummber",
                                    }, {
                                        value: moment(fifthData).format(Defines.Format.PrintDate),
                                        header: "lastInboundPickFromData",
                                    }, {
                                        value: moment(dateVal).format(Defines.Format.PrintDate),
                                        header: "lastInboundPickToData",
                                    }, {
                                        value: new Date(dateVal._d),
                                        header: header.key,
                                    },
                                );
                                setData(newData);
                            } else {
                                newData.push(
                                    {
                                        value: statusValue,
                                        header: "dropInboundAppointmentStatus",
                                    }, {
                                        value: currentPickOrDelNumber,
                                        header: "inDeliverNummber",
                                    }, {
                                        value: moment(fifthData).format(Defines.Format.PrintDate),
                                        header: "lastInboundDropFromData",
                                    }, {
                                        value: moment(dateVal).format(Defines.Format.PrintDate),
                                        header: "lastInboundDropToData",
                                    }, {
                                        value: new Date(dateVal._d),
                                        header: header.key,
                                    },
                                );
                                setData(newData);
                            }
                        } else {
                            if (cellData.key === 'outboundPickAppointment') {
                                newData.push(
                                    {
                                        value: statusValue,
                                        header: "pickOutboundAppointmentStatus",
                                    }, {
                                        value: currentPickOrDelNumber,
                                        header: "outPickNummber",
                                    }, {
                                        value: moment(fifthData).format(Defines.Format.PrintDate),
                                        header: "lastOutboundPickFromData",
                                    }, {
                                        value: moment(dateVal).format(Defines.Format.PrintDate),
                                        header: "lastOutboundPickToData",
                                    }, {
                                        value: new Date(dateVal._d),
                                        header: header.key,
                                    },
                                );
                                setData(newData);
                            } else {
                                newData.push(
                                    {
                                        value: statusValue,
                                        header: "outboundAppointmentStatus",
                                    }, {
                                        value: currentPickOrDelNumber,
                                        header: "outDeliverNummber",
                                    }, {
                                        value: moment(fifthData).format(Defines.Format.PrintDate),
                                        header: "lastOutboundDropFromData",
                                    }, {
                                        value: moment(dateVal).format(Defines.Format.PrintDate),
                                        header: "lastOutboundDropToData",
                                    }, {
                                        value: new Date(dateVal._d),
                                        header: header.key,
                                    },
                                );
                                setData(newData);
                            }
                        }
                    }}
                    onKeyDown={e => {
                        if (e.key === 'Escape') {
                            onSetBlur();
                            if (inputReference.current) {
                                setDisplayState(redonly);
                            }
                        }
                    }}
                    statusOptions={selectNameOption}
                    rowSelectApptsColorClass={rowSelectApptsColorClass}
                    delOrPickNumberColor={delOrPickNumberColor}
                    subIsDisabled={subIsDisabled}
                    showRemoveIcon={showRemoveIcon}
                />;
            case 'dateTime':
                return <DatePicker
                    open={displayState === editable}
                    ref={inputReference}
                    onBlur={() => {
                        setTimeout(() => {
                            if (inputReference.current) {
                                setDisplayState(redonly);
                            }
                        }, 0);
                    }}
                    suffixIcon={null}
                    showTime
                    minuteStep={15}
                    className='spreadsheet-picker'
                    value={tempData ? moment(tempData, Defines.Format.PrintDateTime) : ''}
                    format={Defines.Format.PrintDateTime}
                    onKeyDown={e => {
                        if (e.key === 'Escape') {
                            onSetBlur();
                            if (inputReference.current) {
                                setDisplayState(redonly);
                            }
                        }
                    }}
                    onChange={(value) => {
                        if (value === null) {
                            setData("");
                        }
                    }}
                    onOk={value => {
                        if (inputReference.current) {
                            setDisplayState(redonly);
                        }
                        setData(value === null ? '' : value._d);
                    }}
                    showToday={false}
                />;
            case 'date':
                return <DatePicker
                    open={displayState === editable}
                    ref={inputReference}
                    onBlur={(value) => {
                        if (inputReference.current) {
                            setDisplayState(redonly);
                        }
                        onSetBlur();
                    }}
                    onChange={(value) => {
                        if (inputReference.current) {
                            setDisplayState(redonly);
                        }
                        setData(value === null ? '' : value._d)
                    }}
                    suffixIcon={null}
                    className='spreadsheet-picker'
                    value={tempData ? moment(tempData, Defines.Format.PrintDate) : ''}
                    format={Defines.Format.PrintDate}
                    onKeyDown={e => {
                        if (e.key === 'Escape') {
                            onSetBlur();
                            if (inputReference.current) {
                                setDisplayState(redonly);
                            }
                        }
                    }}
                    showToday={false}
                    
                />;
            case 'integer':
                const mainOrder = loadArea.loadsAssignedOrders.find(order => 
                        order?.rowState?.orderData?.id === rowState.orderObjectId && !order?.rowState?.isSub);

                return <InputNumber type="number" min={0}
                    ref={inputReference}
                    value={tempData ? tempData : ''}
                    onBlur={(e) => {

                        if (inputReference.current) {
                            setDisplayState(redonly);
                        }                        
                        if (isSub && (tempData || tempData === null) && e.target.value > mainOrder?.rowState?.orderData?.[header.key]) {
                            throwNeededMessage(header.key);
                        } 
                        if ((cellData.key === 'palletCount' || cellData.key === 'space' || cellData.key === 'weight') && Number(e.target.value) < 1) {
                            message.error("Null and negative values are not allowed");
                        } else if (cellData.key === 'cases' && Number(e.target.value) < 0) {
                            message.error("Negative values are not allowed");
                        } else if (cellData.key === 'weight' && Number(e.target.value) > (rowState.orderData.palletCount * 2600)) {
                            message.error("The weight of one pallet cannot exceed 2600 pounds. Please adjust the weight");                        
                        } else if (cellData.key === 'palletCount' && Number(e.target.value) < (rowState.orderData.weight / 2600)) {
                            message.error("The weight of one pallet cannot exceed 2600 pounds. Please adjust the weight");
                        } else {
                            setData(e.target.value, cellData.key)
                        }
                    }}
                    onDoubleClick={() => handleUnColor()}
                    className={`${isSub ? 'subBG' : updatedValue === true ? 'changed-value__BG' : 'spreadsheet-number-input' }`}
                />;
            case 'decimal':
                return <InputNumber type="number" min={0} ref={inputReference}
                    value={tempData ? tempData : ''}
                    onBlur={(e) => {
                        if (inputReference.current) {
                            setDisplayState(redonly);
                        }
                        setData(e.target.value)
                    }}
                    className='spreadsheet-number-input'
                />;
            case 'textInput':
                return <input
                    defaultValue={tempData ? tempData : ''}
                    ref={inputReference}
                    onBlur={(value) => {
                        if (inputReference.current) {
                            setDisplayState(redonly);
                        }
                        setData(value.currentTarget.value)
                    }}
                    format={Defines.Format.PrintDate}
                    style={{ textAlign: 'center', width: '100%'}}
                />;
            case 'textInputReadOnly':
                return <div style={{ display: 'flex'}}>
                    <input
                        defaultValue={tempData ? tempData : ''}
                        className='disabled__input'
                        disabled
                    />
                </div>
            case 'textInputPO':
                let colorPO = '';
                let BGColorPO = '';
                let fontWeightPO = 'inherit';
                if (Number(rowState?.orderData.palletCount) !== Number(rowState?.orderData.space)) {
                    colorPO = '#DA2FC9';
                    fontWeightPO = 'bold';
                }
                if (selectedOrder && selectedOrder[0] && rowState?.orderObjectId === selectedOrder[0].rowState?.orderObjectId) {
                    BGColorPO = 'lightGray'
                }
                let asterisks = '';
                for(let i = 0; i < secondValue; i++) {
                    asterisks += '*';
                }
                return <div style={{ display: 'flex'}}>
                    <input
                        defaultValue={tempData ? tempData : ''}
                        className={`disabled__input ${secondValue ? 'po__data' : ''} `}
                        style={{ color: colorPO, background: BGColorPO, fontWeight: fontWeightPO }}
                        onBlur={(value) => {
                            if (inputReference.current) {
                                setDisplayState(redonly);
                            }
                            setData(value.currentTarget.value)
                        }}
                    />
                    {secondValue &&
                        <span
                            className='disabled__input asterisk'
                            style={{ color: colorPO, background: BGColorPO, fontWeight: fontWeightPO }}
                            onClick={() => setHistoryModalOpen(true)}>
                        {asterisks}
                        </span>
                    }
                </div>
            case 'currency': return <CurrencyInput
                ref={inputReference}
                value={valueData ? valueData : ''}
                className='spreadsheet-number-input'
                thousandseparator="true"
                prefix="$"
                style={{ padding:0 }}
                onValueChange={value => setValueData(value) }
                onBlur={() => {
                    setTimeout(() => {
                        if (inputReference.current) {
                            setDisplayState(redonly);
                        }
                    }, 0);
                    setData(valueData);
                }}
            />;
            case 'select':
                let selectOptions;
                let selectNameOptions;
                if (header.key === 'pickRestriction' || header.key === 'dropRestriction') {
                    selectOptions = selectOptionsPalletTypes;
                    selectNameOptions = selectNameOptionsPalletTypes;
                } else {
                    selectOptions = selectOption;
                    selectNameOptions = selectNameOption;
                }
                return <Select ref={inputReference} value={selectOptions[`${tempData}`]}
                    className={`rowSelect ${rowSelectAppts_color__class} `}
                    onChange={handleSelectChange}
                    onBlur={() => {
                        if (inputReference.current) {
                            setDisplayState(redonly);
                        }
                    }}
                    defaultValue={selectOptions[`${tempData}`]}>
                    {selectNameOptions.map((item, index) => {
                        return <Select.Option 
                                key={index} 
                                value={item.id} 
                                className={`${
                                    item.id === 2 && (header.key === 'inboundAppointmentStatus' || 
                                                    header.key === 'outboundAppointmentStatus') ? 'open_style' :
                                    item.id === 1 && (header.key === 'inboundAppointmentStatus' || 
                                                    header.key === 'outboundAppointmentStatus') ? 'change_style' :
                                    item.id === 3 && (header.key === 'inboundAppointmentStatus' || 
                                                    header.key === 'outboundAppointmentStatus') ? 'pending_style' :
                                    item.id === 4 && (header.key === 'inboundAppointmentStatus' || 
                                                    header.key === 'outboundAppointmentStatus') ? 'confirmed_style' :
                                    ''} `}
                                    >
                                {item.name}
                            </Select.Option>
                    })}
                </Select>;
            case 'selectLocations':
                let tooltipData;
                const contactData = resource === 'outboundload' ? rowState?.orderData?.outContactTypeDetails : ''
                if (header.key === 'dropLocation') {
                    tooltipData = tooltipForLocation(rowState?.orderData?.dropWorkingDaysStr, contactData)
                }
                return <Tooltip title={tooltipData}>
                    <Select 
                        value={getNameById(tempData)}
                        className='rowSelect'
                        onChange={handleSelectChange}
                        defaultValue={getNameById(tempData)}>
                        {locations?.map((item, index) => {
                            return <Select.Option 
                                    key={index} 
                                    value={item.value}>
                                    {item.text}
                                </Select.Option>
                        })}
                    </Select>
                </Tooltip>;
            case 'selectManifest':
                return <Select ref={inputReference} value={tempData} 
                    className={'rowSelect'}
                    onBlur={(value) => {
                        if (inputReference.current) {
                            setDisplayState(redonly);
                        }
                    }}
                    onChange={handleSelectChangeManifest}
                    defaultValue={tempData}>
                    {weekList.map((item, index) => (
                        <Select.Option key={index} value={item.value}>
                            {item.text}
                        </Select.Option>
                    ))}
                </Select>;
            default: return <input
                style={{ backgroundColor: 'yellow', boxSizing: 'inherit', width: '100%' }}
                value={getData()}
                ref={inputReference}
                onChange={event => setData(event.target.value)}
                onBlur={() => {
                    if (inputReference.current) {
                        setDisplayState(redonly);
                    }
                }} />;
        }
    };

    const getDataWithTooltip = () => tooltip ? (<Tooltip title={GenerateMessages([tooltip])}>
        {getData()}
    </Tooltip>) : getData();

    const dataDisplay = () => displayState === redonly ? getDataWithTooltip() : getInput();

    const cellDefaultStyle = {
        maxWidth: `${width}px`,
        width: `${width}px`,
        minWidth: `${width}px`,
        height: `${itemSize}px`
    };

    const onSetFocus = () => {
        // startOnScroll(offsetWidth);
        if (cellData.editable) {
            setDisplayState(editable);
        }
        setItemDisplayState(resource, rowState, header, cellData.editable);
    };

    const onSetBlur = () => {
        setItemDisplayState(resource, rowState, header, false);
    };

    const editableFunction = (cellData) => (
        cellData.editable ? setDisplayState(editable) : null
    )

    if (cellData.key === 'puDate' && !rowState?.orderData?.readyDate) {
            cellData.editable = false;
    };

    const color = rowState.type === 'load' ? 'white' : '#101C1C';

    const isHideForOutboundLoadPlanning = (
                (cellData.key === "outboundPickIn" && resource === 'outboundload') || 
                (cellData.key === "outboundPickOut" && resource === 'outboundload') ||
                (cellData.key === "outboundDropIn" && resource === 'outboundload') ||
                (cellData.key === "outboundDropOut" && resource === 'outboundload') ||
                (cellData.key === "inboundPickIn" && resource === 'load') || 
                (cellData.key === "inboundPickOut" && resource === 'load') ||
                (cellData.key === "inboundDropIn" && resource === 'load') ||
                (cellData.key === "inboundDropOut" && resource === 'load') ||
                cellData.key === "carrier" ||
                cellData.key === "rate" ||
                cellData.key === "target" ||
                cellData.key === "driver_truck_Trailer_VIN" ||
                cellData.key === "efs") &&
                location.pathname.split('/')[1] === 'loadplanning';

    return !isHideForOutboundLoadPlanning ? (
        <>
            <div onFocus={() => onSetFocus()} onClick={() => {
                editableFunction(cellData);
            }}
                className='table-body-cell'
                style={{ ...cellDefaultStyle, ...cellStyle, color: color, border: borderColor }}
                tabIndex={displayState === redonly ? 0 : -1}>
                {dataDisplay()}
            </div>
            {isDetailsModalOpen &&
                <LoadDetailsModal
                    isDetailsModalOpen={isDetailsModalOpen}
                    setDetailsModalToOpen={setDetailsModalToOpen}
                    loadId={rowState?.loadData.id}
                    resource={resource}
                />
            }
            {isHistoryModalOpen &&
                <HistoryModal
                    isHistoryModalOpen={isHistoryModalOpen}
                    setHistoryModalOpen={setHistoryModalOpen}
                    resource={resource}
                    rowState={rowState}
                    cellDataKey={cellData.key}
                />
            }
        </>
    ) : null;
};
export default connect(state => ({
    state: state
}), dispatch => ({
    setItemData: (resource, value, rowState, header) => dispatch(setItemData(resource, value, rowState, header)),
    setItemDisplayState: (resource, rowState, header, enable) => dispatch(setItemDisplayState(resource, rowState, header, enable))
}))(Cell);