import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import Modal from 'react-modal';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { DatePicker, Dropdown, Menu, Space } from 'antd';
import moment from 'moment';
import Select from 'react-select'
import { getFavoriteLocationDoorList, getAllLocationDoorList } from '../../../../store/actions/customerOrderLocations/customerOrderLocations';
import { customerOrderNewOrders, addCustomerOrder, editCustomerOrder, setFormValues, getAllActiveTemperaturesList } from '../../../../store/actions/customerOrderNewOrders/customerOrderNewOrders';
import { revisionCustomerOrder } from '../../../../store/actions/customerOrderInProgress/customerOrderInProgress';
import { getCustomerOrderRejected } from '../../../../store/actions/customerRejectedOrders/customerRejectedOrders';
import { customerOrderInProgress } from '../../../../store/actions/customerOrderInProgress/customerOrderInProgress';
import Loading from '../../screen/loading';
import ErrorModal from './errorModal';
import store from '../../../../store';

const CustomModal = ({ 
    modalIsOpen,
    totalOrders,
    totalOrdersRequested,
    setIsOpen,
    addCustomerOrder,
    editCustomerOrder,
    customerOrderNewOrders,
    customerOrderInProgress,
    getCustomerOrderRejected,
    editMode,
    revisionMode,
    revisionCustomerOrder,
    data,
    setEditWarningModaIsOpen,    
    getFavoriteLocationDoorList,
    getAllLocationDoorList,
    favoriteLocationsDoorsList,
    allLocationsDoorsList,
    favoriteDoorsLocationsLoading,
    allDoorsLocationsLoading,
    setFormValues,
    formValues,
    setSaveBtnClicked,
    setSaveAndNewBtnClicked,
    setSaveAndCloneBtnClicked,
    getAllActiveTemperaturesList,
    activeTemperaturesList,
  }) => {

  const resource = "CustomerOrder";
  const [orderAdded, setOrderAdded] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [dataChanged, setDataChanged] = useState(false);
  const [errorModalText, setErrorModalText] = useState('');
  let submitAction = '';

  // get customerId
  const token = store.getState().auth.userToken;
  const parts = token.split('.');
  let customerId = null;
  if (parts.length > 1) {
      const part = parts[1];
      const jPart = JSON.parse(atob(part));
      const userData = JSON.parse(jPart["http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata"]);
      customerId = userData.CustomerId;
  };

  useEffect(() => {
    getFavoriteLocationDoorList('location', 'locationdoorlistbyparentid', customerId);
    getAllLocationDoorList('location', 'locationdoorlist');
    getAllActiveTemperaturesList('OrderTemperatures/availableordertemperaturelist');
  }, [getFavoriteLocationDoorList, getAllLocationDoorList, customerId, getAllActiveTemperaturesList]);
  
  favoriteLocationsDoorsList?.length && favoriteLocationsDoorsList[0].value !== '' && favoriteLocationsDoorsList.unshift({value: '', text: ''});
  allLocationsDoorsList?.length && allLocationsDoorsList[0].value !== '' && allLocationsDoorsList.unshift({value: '', text: ''});

  const optionsFavoriteLocations = favoriteLocationsDoorsList && favoriteLocationsDoorsList.map(item => { 
    return {...item, 'label': item.text }
  })

  const optionsAllLocations = allLocationsDoorsList && allLocationsDoorsList.map(item => { 
    return {...item, 'label': item.text }
  })

  const getTextFromValueFavoriteLocations = value => {
    return favoriteLocationsDoorsList?.length ? favoriteLocationsDoorsList?.filter(item => item.value === value)[0].text : [];
  };

  const getTextFromValueAllLocations = value => {
    return allLocationsDoorsList?.length ? allLocationsDoorsList?.filter(item => item.value === value)[0].text : [];
  };
  
  const handleSubmitSave = submitAdd => {
    submitAdd();
    setButtonDisabled(false);
  };

  const handleSubmitClone = (submit) => {
    submit()
    openModal();
  };

  const handleEdit = (submitEdit) => {
    submitEdit();
    setButtonDisabled(false);
  }

  useEffect(() => {
    if (orderAdded && !editMode) {
      setTimeout(() => {
        customerOrderNewOrders('customerOrder', 'myorderorderlist');
        customerOrderInProgress('customerOrder', 'area');
        setIsOpen(false);
      }, 3000)
    }
  }, [totalOrders, dataChanged, orderAdded, editMode, customerOrderNewOrders, customerOrderInProgress, setIsOpen]);

  useEffect(() => {
    if (orderAdded && editMode) {
      setTimeout(() => {
        customerOrderInProgress('customerOrder', 'area');
        customerOrderNewOrders('customerOrder', 'myorderorderlist');
        getCustomerOrderRejected('CustomerOrder', 'myrejectedorderorderlist');
        setIsOpen(false);
      }, 3000)
    }
  }, [totalOrders, totalOrdersRequested, orderAdded, editMode, customerOrderInProgress, customerOrderNewOrders, getCustomerOrderRejected, setIsOpen]);

  const afterOpenModal = () => {
    // references are now sync'd and can be accessed.
  };

  const closeModal = () => {
    setIsOpen(false);
    if (!editMode) {
      setFormValues({});
      customerOrderNewOrders('customerOrder', 'myorderorderlist');
      customerOrderInProgress('customerOrder', 'area');
    }
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      minWidth: '580px',
      minHeight: '440px',      
      borderRadius: '8px',
      padding: '6px 12px',
    },
  };

  const validationSchema = Yup.object().shape({
    pickLocationId: Yup.string().required('Required'),
    dropLocationId: Yup.string().required('Required'),
    readyDate: Yup.date().required('Required'),
    dueDate: Yup.date().required('Required'),
    palletCount: Yup.number().integer().required('Required'),
    space: Yup.number().integer().required('Required'),
    poNumber: Yup.string().required('Required'),
    soNumber: Yup.string(),
    weight: Yup.number().integer().required('Required'),
    temperature: Yup.number().required('Required'),
  });

  const returnTextFromValue = (value, list) => {
    for(let i = 0; i < list?.length; i ++) {
      if (list[i].id === value) {
        return list[i].name;
      }
    }
  }

  const setDropLocation = (option, setFieldValue) => {
    setFieldValue('dropLocationId', option.value);
    setFieldValue('dropRegionId', option.outboundRegionId)
  }

  const colourStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: state.isFocused ? "lightGray" : "lightGray",
      borderRadius: '8px',
      boxShadow: 'none',
      minHeight: '39px',
    }),
    menu: (base, state) => ({
      ...base,
      zIndex: '20',
    }),
    valueContainer: (base, state) => ({
      display: 'flex',
      alignItems: 'self-end',
      paddingLeft: '5px',
      maxWidth: '224px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }),
    singleValue: (base, state) => ({
      maxWidth: '224px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }),
  };

  if (favoriteDoorsLocationsLoading || allDoorsLocationsLoading || orderAdded) {
    return (
      <Modal
        isOpen={modalIsOpen}
        style={customStyles}
        >
        <div style={{ marginTop: '30% '}}>
          <Loading />
        </div>
      </Modal>
    )
  };

  const handleSetFieldValuePallet = (data, values, setFieldValue) => {
    setFieldValue('space', data.target.value);
    setFieldValue('palletCount', data.target.value)
  }

  const handleSetFieldValueSpace = (data, values, setFieldValue) => {
    if (values.palletCount > 0 && data.target.value > values.palletCount) {
      setFieldValue('space', '');
      setButtonDisabled(true);
      setErrorModalText(`Space count can't be more than pallet count`);
    } else {
      setFieldValue('space', data.target.value);
      setButtonDisabled(false)
    }
  }

  return (
    <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
        >
        <div>
          <div className='modal_header'>
            <div className='modal_title'>{revisionMode ? 'Revision Order' : editMode ? 'Edit Order' : 'Add Order'}</div>
            <button onClick={closeModal} className='modal_x-btn'>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0ZM14.3 14.3C13.91 14.69 13.28 14.69 12.89 14.3L10 11.41L7.11 14.3C6.72 14.69 6.09 14.69 5.7 14.3C5.31 13.91 5.31 13.28 5.7 12.89L8.59 10L5.7 7.11C5.31 6.72 5.31 6.09 5.7 5.7C6.09 5.31 6.72 5.31 7.11 5.7L10 8.59L12.89 5.7C13.28 5.31 13.91 5.31 14.3 5.7C14.69 6.09 14.69 6.72 14.3 7.11L11.41 10L14.3 12.89C14.68 13.27 14.68 13.91 14.3 14.3Z" fill="#678482"/>
              </svg>
            </button>
          </div>
          <Formik
            enableReinitialize
            initialValues={{
              pickLocationId: editMode ? data.pickLocationId : formValues?.pickLocationId ? formValues?.pickLocationId : '',
              dropLocationId: editMode ? data.dropLocationId : formValues?.dropLocationId ? formValues?.dropLocationId : '',
              readyDate: editMode ? moment.utc(data.readyDate) : formValues?.readyDate ? moment.utc(formValues?.readyDate) : '',
              dueDate: editMode ? moment.utc(data.dueDate) : formValues?.dueDate ? moment.utc(formValues?.dueDate) : '',
              palletCount: revisionMode && data.revisionPalletCount ? data.revisionPalletCount : editMode ? Number(data.palletCount) : formValues?.palletCount ? formValues?.palletCount : '',
              space: revisionMode && data.revisionSpace ? data.revisionSpace : editMode ? Number(data.space) : formValues?.space ? formValues?.space : '',
              poNumber: editMode ? data.poNumber : formValues?.poNumber ? formValues?.poNumber : '',
              weight: revisionMode && data.revisionWeight ? data.revisionWeight : editMode ? Number(data.weight) : formValues?.weight ? formValues?.weight : '',
              soNumber: editMode ? data.soNumber : formValues?.soNumber ? formValues?.soNumber : '',
              temperature: editMode ? data.temperature : formValues?.temperature ? formValues?.temperature : '',
              customerOrderNotes: revisionMode && data.revisionNotes ? data.revisionNotes : editMode ? data.customerOrderNotes : formValues?.customerOrderNotes ? formValues?.customerOrderNotes : '',
              dropRegionId: formValues?.dropRegionId ? formValues?.dropRegionId : '0',
              id: editMode ? data.id : '0',
              orderId: editMode ? data.id : '0',
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm, setSubmitting, setFieldValue }) => {
              setFormValues(resource, values);
              if(revisionMode) {
                setButtonDisabled(true)
                setTimeout(() => {
                  setSubmitting(false);
                  if (
                    values.palletCount !== Number(data.revisionPalletCount || 0) ||
                    values.space !== Number(data.revisionSpace || 0) ||
                    values.weight !== Number(data.revisionWeight || 0) ||
                    values.customerOrderNotes !== (data.revisionNotes || '')
                  )
                    {
                      if (Number(values.space) > 30) {
                        setFieldValue('space', '');
                        setErrorModalText(`Space count can't be more than 30`);
                      } else if (values.weight === '0' && !data.isTbd) {
                        setErrorModalText('Pallet, Space, and LBS are required. Please fill them');
                        setFieldValue('weight', '');
                      } else if ((values.palletCount === '0' || values.space === '0') && !data.isTbd) {
                        setErrorModalText('Pallet, Space, and LBS are required. Please fill them');
                        setFieldValue('palletCount', '');
                        setFieldValue('space', '');
                      } else if (Number(values.weight) > Number(values.palletCount) * 2600) {
                        setErrorModalText(`Maximum weight for 1 pallet can be  2600 pounds`);
                        setFieldValue('weight', '');
                      } else {
                        revisionCustomerOrder('CustomerOrder', values);
                        setOrderAdded(true);
                        setDataChanged(true);
                      }
                      setFormValues({});
                    } else {
                      setIsOpen(false);
                      setEditWarningModaIsOpen(true);
                    }
                }, 400);
              } else if (editMode && !revisionMode) {
                setButtonDisabled(true)
                setTimeout(() => {
                  setSubmitting(false);
                  if (
                    values.pickLocationId !== data.pickLocationId ||
                    values.dropLocationId !== data.dropLocationId ||
                    (
                      new Date(values.readyDate._d).getDate() !== new Date(data.readyDate).getDate() ||
                      new Date(values.readyDate._d).getMonth() !== new Date(data.readyDate).getMonth()
                    ) ||
                    (
                      new Date(values.dueDate._d).getDate() !== new Date(data.dueDate).getDate() ||
                      new Date(values.dueDate._d).getMonth() !== new Date(data.dueDate).getMonth()
                    ) ||
                    values.palletCount !== data.palletCount ||
                    values.space !== data.space ||
                    values.soNumber !== data.soNumber ||
                    values.poNumber !== data.poNumber ||
                    values.weight !== data.weight ||
                    values.temperature !== data.temperature ||
                    values.customerOrderNotes !== data.customerOrderNotes
                  )
                    {
                      if (Number(values.space) > 30) {
                        setFieldValue('space', '');
                        setErrorModalText(`Space count can't be more than 30`);
                      } else if (values.weight === '0' && !data.isTbd) {
                        setErrorModalText('Pallet, Space, and LBS are required. Please fill them');
                        setFieldValue('weight', '');
                      } else if ((values.palletCount === 0 || values.space === '0') && !data.isTbd) {
                        setErrorModalText('Pallet, Space, and LBS are required. Please fill them');
                        setFieldValue('space', '');
                        setFieldValue('palletCount', '');
                      } else if (Number(values.weight) > Number(values.palletCount) * 2600) {
                        setErrorModalText(`Maximum weight for 1 pallet can be  2600 pounds`);
                        setFieldValue('weight', '');
                      } else {
                        editCustomerOrder('CustomerOrder', values);
                        setOrderAdded(true);
                        setDataChanged(true);
                      }
                    } else {
                      setIsOpen(false);
                      setEditWarningModaIsOpen(true);
                    }
                }, 400);
              } else {
                setButtonDisabled(true)
                setTimeout(() => {
                  setSubmitting(false);
                  setOrderAdded(false);
                  if (
                      values.pickLocationId === '' &&
                      values.dropLocationId === '' &&
                      values.readyDate === '' &&
                      values.dueDate === '' &&
                      values.palletCount === '' &&
                      values.space === '' &&
                      values.poNumber === '' &&
                      values.weight === '' &&
                      values.temperature === ''
                    ) {
                      setButtonDisabled(true);
                    } else {
                      values.space <= 30 && (values.weight <= values.palletCount * 2600) &&
                      addCustomerOrder('CustomerOrder', values);   

                      if (submitAction === "first") {
                        setSaveBtnClicked(true);
                        setSaveAndNewBtnClicked(false);
                        setSaveAndCloneBtnClicked(false);
                      }
                      if (submitAction === "second" ) {
                        setSaveBtnClicked(false);
                        setSaveAndNewBtnClicked(true);
                        setSaveAndCloneBtnClicked(false);
                      }
                      if (submitAction === "third" ) {
                        setSaveBtnClicked(false);
                        setSaveAndNewBtnClicked(false);
                        setSaveAndCloneBtnClicked(true);
                      }
                      values.space > 30 && setFieldValue('space', '');
                      values.space > 30 && setErrorModalText(`Space count can't be more than 30`);
                      values.weight > values.palletCount * 2600 && setErrorModalText(`Maximum weight for 1 pallet can be  2600 pounds`);
                      values.weight > values.palletCount * 2600 && setFieldValue('weight', '');
                    }
              }, 400);
              }}
            }
          >
            {({ isSubmitting, isValid, values, setFieldValue, errors, touched, handleSubmit }) => (
              <Form>
              <div className={`modal_formik_one_line_location ${revisionMode ? "cursor__notAllowed" : ""}`}>
                <>
                  <div className='pickLocation_title'>Pick Location</div>
                  <Select 
                    options={optionsFavoriteLocations}
                    name="pickLocation"
                    value={{label: getTextFromValueFavoriteLocations(values.pickLocationId)}}
                    className={`formik_select ${touched.pickLocationId && errors.pickLocationId ? "dropdown_error" : ""} ${revisionMode ? "form_disabled" : ""} `}
                    onChange={option => setFieldValue('pickLocationId', option.value)}
                    styles={colourStyles}
                    components={{
                      IndicatorSeparator: () => null
                    }}
                    isDisabled={revisionMode}
                  />
                </>
                <>
                    <div className='dropLocation_title'>Drop Location</div>
                    <Select 
                      options={optionsAllLocations}
                      name="dropLocation"
                      value={{label: getTextFromValueAllLocations(values.dropLocationId)}}
                      className={`formik_select ${touched.dropLocationId && errors.dropLocationId ? "dropdown_error" : ""} ${revisionMode ? "form_disabled" : ""} `}
                      onChange={option => setDropLocation(option, setFieldValue)}
                      styles={colourStyles}
                      components={{
                        IndicatorSeparator: () => null
                      }}
                      isDisabled={revisionMode}
                    />
                  </>
                </div>
                <div className='formik_form modal_formik_one_line formik-date_input'>
                  <div className='readyDate_container'>
                    <div className={`readyDate_text ${revisionMode && 'color_for_revision'} ${!values.readyDate && 'readyDate_text_big'}`}>Ready Date</div>
                    <Field
                      name="readyDate"
                      component={DatePicker}
                      type="date"
                      suffixIcon={false}
                      className="formik_date"
                      placeholder=""
                      value={values.readyDate ? values.readyDate : ''}
                      onChange={date => setFieldValue('readyDate', date)}
                      style={touched.readyDate && errors.readyDate && { border: '1px solid red' } }
                      disabled={revisionMode}
                    />
                  </div>
                  <div className='readyDate_container'>
                    <div className={`readyDate_text ${revisionMode && 'color_for_revision'} ${!values.dueDate && 'readyDate_text_big'}`}>Due Date</div>
                    <Field
                      name="dueDate"
                      component={DatePicker}
                      suffixIcon={false}
                      type="date"
                      className="formik_date"
                      value={values.dueDate ? values.dueDate : ''}
                      placeholder=""
                      onChange={date => setFieldValue('dueDate', date)}
                      style={touched.dueDate && errors.dueDate && { border: '1px solid red' } }
                      disabled={revisionMode}
                    />
                  </div>
                </div>
                <div className='modal_formik_one_line'>
                  <div className='modal_pal-spa__container'>
                      <div className={'data_container modal__pallet-field'}>
                        <div className='data_placeholder data_placeholder_input'>PAL</div>
                        <Field
                          name="palletCount"
                          style={touched.palletCount && errors.palletCount && { border: '1px solid red' } }
                          onChange={data => handleSetFieldValuePallet(data, values, setFieldValue)}
                        />
                      </div>
                      <div className={'data_container'}>
                        <div className='data_placeholder data_placeholder_input'>SPA</div>
                        <Field
                          name="space"
                          style={touched.space && errors.space && { border: '1px solid red' } }
                          onChange={data => handleSetFieldValueSpace(data, values, setFieldValue)}
                        />
                      </div>
                  </div>
                  <div className={'data_container'}>
                    <div className='data_placeholder data_placeholder_input'>PO#</div>
                    <Field
                      name="poNumber"
                      style={touched.poNumber && errors.poNumber && { border: '1px solid red' } }
                      className={`${revisionMode ? "form_disabled" : ""} `}
                      disabled={revisionMode}
                    />
                  </div>
                </div>
                <div className='formik_form modal_formik_one_line'>
                  <div className={'data_container'}>
                    <div className='data_placeholder data_placeholder_input'>LBS</div>
                    <Field
                      name="weight"
                      style={touched.weight && errors.weight && { border: '1px solid red' } }
                    />
                  </div>
                  <div className={'data_container'}>
                    <div className='data_placeholder data_placeholder_input'>SO#</div>
                    <Field
                      name="soNumber"
                      style={touched.soNumber && errors.soNumber && { border: '1px solid red' } }
                      className={`${revisionMode ? "form_disabled" : ""} `}
                      disabled={revisionMode}
                    />
                  </div>
                </div>
                <div className='formik_form modal_formik_one_line'>

                  <Dropdown 
                    className={`formik_select_temperature formik_dropdown ${touched.temperature && errors.temperature ? "dropdown_error" : ""} ${revisionMode ? "form_disabled" : ""} `}
                    placement="bottomRight"
                    trigger="click"
                    name="temperature"
                    value={values.temperature}
                    disabled={revisionMode}
                    overlay={(
                      <Menu className="formik_option" onClick={data => setFieldValue('temperature', Number(data.key))}>
                        {activeTemperaturesList?.length && activeTemperaturesList.map(item => {
                            return <Menu.Item key={item.id}>{item.name}</Menu.Item>
                          })
                        }
                      </Menu>
                    )} 
                  >
                    <Space>
                      <div className='temperature_container' onClick={e => e.preventDefault()}>
                        <div className={`${!values.temperature ? 'asPlaceholder' : 'upperPlaceholder'}`}><span className='temperature_text'>Temp</span></div>
                        <div className='selected_temperature'>{values.temperature && returnTextFromValue(values.temperature, activeTemperaturesList)}</div>
                      </div>
                      <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.4625 0.2925L5.5825 4.1725L1.7025 0.2925C1.3125 -0.0975 0.6825 -0.0975 0.2925 0.2925C-0.0975 0.6825 -0.0975 1.3125 0.2925 1.7025L4.8825 6.2925C5.2725 6.6825 5.9025 6.6825 6.2925 6.2925L10.8825 1.7025C11.2725 1.3125 11.2725 0.6825 10.8825 0.2925C10.4925 -0.0875 9.8525 -0.0975 9.4625 0.2925Z" fill="#204D49"/>
                      </svg>
                    </Space>
                  </Dropdown>
                </div>
                <div className='formik_form modal_formik_full_line modal_notes'>
                  <Field
                    name="customerOrderNotes"
                    placeholder="Notes"
                    style={touched.customerOrderNotes && errors.customerOrderNotes && { border: '1px solid red' } }
                  />
                </div>
                {revisionMode ?
                  <div className='formik_button__container'>
                    <button 
                      className='formik_btn formik_btn__save_changes request_button' 
                      type="submit" 
                      disabled={!isValid || isSubmitting || buttonDisabled}
                      onClick={() => { handleEdit(handleSubmit, values) }}
                      >
                      REQUEST
                    </button>
                    <button 
                      className='formik_btn cancel' 
                      type="submit" 
                      onClick={closeModal}
                      >
                      CANCEL
                    </button>
                  </div> :
                editMode ? 
                  <div className='formik_button__container'>
                  <button 
                      className='formik_btn formik_btn__save_changes' 
                      type="submit" 
                      disabled={!isValid || isSubmitting || buttonDisabled}
                      onClick={() => { handleEdit(handleSubmit, values) }}
                      >
                      SAVE CHANGES
                    </button>
                    <button 
                      className='formik_btn cancel' 
                      type="submit" 
                      onClick={closeModal}
                      >
                      CANCEL
                    </button>
                  </div> :
                <div className='formik_form formik_button__container'>
                  <button 
                    className='formik_btn formik_btn__save' 
                    type="submit" 
                    disabled={!isValid || isSubmitting || buttonDisabled}
                    onClick={() => { submitAction = "first"; handleSubmitSave(handleSubmit) }}
                    >
                    SAVE
                  </button>
                  <button 
                    className='formik_btn' 
                    type="submit" 
                    disabled={!isValid || isSubmitting || buttonDisabled}
                    onClick={() => { submitAction = "second"; handleSubmitClone(handleSubmit) }}
                    >
                    SAVE & NEW
                  </button>
                  <button 
                    className='formik_btn' 
                    type="submit" 
                    disabled={!isValid || isSubmitting || buttonDisabled}
                    onClick={() => { submitAction = "third"; handleSubmitClone(handleSubmit) }}  
                    >
                    CLONE ORDER
                  </button>
                </div>
                }
              </Form>
            )}
          </Formik>
        </div>
        {!!errorModalText &&
          <ErrorModal
              errorModalText={errorModalText}
              setErrorModalText={setErrorModalText}
              message={errorModalText}
              setButtonDisabled={setButtonDisabled}
          />
        }
    </Modal>
  );
};

const mapStateToProps = state => ({
  state: state,
  favoriteLocationsDoorsList: state.customerOrderLocations.favoriteLocationsDoorsList,
  allLocationsDoorsList: state.customerOrderLocations.allLocationsDoorsList,
  favoriteDoorsLocationsLoading: state.customerOrderLocations.favoriteDoorsLocationsLoading,
  allDoorsLocationsLoading: state.customerOrderLocations.allDoorsLocationsLoading,
  totalOrders: state.customerOrderNewOrders.totalOrders,
  totalOrdersRequested: state.customerRejectedOrders.totalRejectedOrders,
  hasError: state.customerOrderNewOrders.hasError,
  actionMessage: state.customerOrderNewOrders.message,
  customerOrderNewOrdersLoading: state.customerOrderNewOrders.loading,
  addCustomerOrderloading: state.customerOrderNewOrders.addCustomerOrderloading,
  formValues: state.customerOrderNewOrders.formValues,
  orderAdded: state.customerOrderNewOrders.orderAdded,
  activeTemperaturesList: state.customerOrderNewOrders.activeTemperaturesList,
});

const mapDispatchToProps = dispatch => ({
  addCustomerOrder: (resource, data) => dispatch(addCustomerOrder(resource, data)),
  editCustomerOrder: (resource, data) => dispatch(editCustomerOrder(resource, data)),
  revisionCustomerOrder: (resource, data) => dispatch(revisionCustomerOrder(resource, data)),
  getFavoriteLocationDoorList: (resource, endPoint, id) => dispatch(getFavoriteLocationDoorList(resource, endPoint, id)),
  getAllLocationDoorList: (resource, endPoint) => dispatch(getAllLocationDoorList(resource, endPoint)),
  customerOrderNewOrders: (resource, endPoint) => dispatch(customerOrderNewOrders(resource, endPoint)),
  customerOrderInProgress: (resource, endPoint) => dispatch(customerOrderInProgress(resource, endPoint)),
  getCustomerOrderRejected: (resource, endPoint) => dispatch(getCustomerOrderRejected(resource, endPoint)),
  setFormValues: (resource, formValues) => dispatch(setFormValues(resource, formValues)),
  getAllActiveTemperaturesList: (endPoint) => dispatch(getAllActiveTemperaturesList(endPoint)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomModal);