import React, { useMemo, useRef, useEffect, useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { VariableSizeList } from 'react-window';
import Split from "react-split-it";
import AutoSizer from 'react-virtualized-auto-sizer';
import { connect } from "react-redux";
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { unAssignOrders, assignOrdersToLoad, assignOrdersToNewLoad, load, 
    resetError, reactOnAvailableItems, reactOnAssignedItems, filterInOutOrders, filterUnassignedOrders }
    from '../../../../store/actions/loadArea/loadArea';
import { getLocations } from '../../../../store/actions/locations/locations';
import { getContentWidth } from '../../../../store/reducers/loadArea/helper/general';
import Row from './row';
import HeaderRow from './headerRow';
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { createSelectable, SelectableGroup } from '../reactSelectable';
import ToolBar from './toolBar';
import Filters from './Filters';
import { createSeparator } from '../../../../store/reducers/loadArea/helper/separator';
import { message } from 'antd';
import Loading from "../../screen/loading";
import { useClientHubOrder } from "../../../hook/hub/useClientHubOrder";
import AssignOrdersModal from './AssignOrdersModal';

const Spreadsheet = props => {
    const scrollSizeSmall = 8;
    const scrollSize = 16;
    const defRowHeight = 18;
    const assignedRef = useRef({});
    const availableRef = useRef({});
    const { selectable, reactOnAssignedItems, reactOnAvailableItems, state, filterInOutOrders, filterUnassignedOrders, getLocations } = props;
    const resource = props.resource;
    const allAvailableOrders = resource === 'load' ? state?.load?.orders : state?.outboundload?.orders;
    const location = useLocation();

    const haseError = state[resource].hasError;
    const resetError = props.resetError;
    const actionMessage = state[resource].message;
    const load = props.load;
    const overIndex = useRef(null);
    const loading = state[resource].loading;
    const loadingLocations = state['locations'].loading;
    const openModal = state[resource].openModal;
    const [assignOrdersModalIsOpen, setAssignOrdersModalIsOpen] = useState(openModal);
    const [assignedOrders, setAssignedOrders] = useState('');
    const [draggableLoad, setDraggableLoad] = useState('');
    const [filterInput, setFilterInput] = useState('');
    const [searchInput, setSearchInput] = useState('');
    const [selectedOrder, setSelectedOrder] = useState('');
    const [onlyTBDOrders, setOnlyTBDOrder] = useState(false);
    const isBookingPage = location.pathname.split('/')[1] === 'booking'; 

    useEffect(() => {
        setAssignOrdersModalIsOpen(openModal);
    }, [assignOrdersModalIsOpen, openModal]);

    const swap = state.auth.profileSettings && state.auth.profileSettings.screens
    && state.auth.profileSettings.screens[resource] ? state.auth.profileSettings.screens[resource].swap : false;
    
    const onDrugStartMouseX = useRef(0);

    useClientHubOrder((message) => {
        if (state.auth.userId !== message.byId) {
            load(resource, 'withoutLoading');
        }
    }, 'notification');

    useEffect(() => {
        load(resource, '');
    }, [load, resource]);

    useEffect(() => {
        if (haseError) {
            message.error(actionMessage, 5);
            resetError(resource);
        }
    }, [haseError, actionMessage, resetError, resource]);

    useEffect(() => {
        getLocations('location', 'locationdoorlist');
    }, [getLocations]);

    const listFirstReference = useRef(null);
    const listSecondReference = useRef(null);
    const listHeaderReference = useRef(null);
    const mainScroll = useRef(null);
    const startOnScroll = width => mainScroll.current.scrollLeft = width;

    const onScrollX = e => {
        if (listHeaderReference.current && listFirstReference.current && listSecondReference.current && e.target) {
            listHeaderReference.current.scrollLeft =
                listFirstReference.current.scrollLeft =
                listSecondReference.current.scrollLeft =
                e.target.scrollLeft;
        }
    };

    const header = useMemo(() => <div ref={listHeaderReference} style={{
        backgroundColor: 'lightblue',
        width: '100%',
        overflow: 'hidden',
        height: '32px'
    }}>
        <HeaderRow resource={resource} />
    </div>, [resource]);
    
    const width = isBookingPage ? 
            getContentWidth(state[resource].headers, 'booking') + 32 :
            getContentWidth(state[resource].headers.filter(header => header.hideForLoadPlanning !== true)) - 10;

    const history = useHistory();

    useEffect(() => {
        filterInOutOrders(resource, 'default');
        history.listen(() => {
            filterInOutOrders(resource, 'default');
            filterUnassignedOrders(resource, 'default');
        });
        return () => {
            filterInOutOrders(resource, 'default');
            filterUnassignedOrders(resource, 'default');
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resource, history]);

    const stateLoadsAssignedOrders = state[resource].loadsAssignedOrders;
    const stateFilterOptions = state[resource].filterOptions;
    const stateFiltersUnassignedOrder = state[resource].filterUnassignedOrders;

    const filteredOrdersList = useMemo(() => stateLoadsAssignedOrders?.filter(item => {
        let ordersList = [];
        if (resource === 'load') {
            // TODO issue with suborders
            ordersList = 
            (stateFilterOptions['pick'] !== 'All' ? item?.rowState?.orderData?.pickLocation === stateFilterOptions['pick'] : true) &&
            (stateFilterOptions['drop'] !== 'All' ? item?.rowState?.orderData?.dropLocation === stateFilterOptions['drop'] : true) &&
            (stateFilterOptions['del'] !== 'All' ? resource === 'load' && item?.rowState?.orderData?.inDeliverNummber === stateFilterOptions['del'] : true) &&
            (stateFilterOptions['apptStatus'] !== 'All' ? 
                Number(item?.rowState?.orderData?.inboundAppointmentStatus) === Number(stateFilterOptions['apptStatus']) &&
                !!item?.rowState?.orderData?.inboundPickAppointment : true) &&
            (stateFilterOptions['stateName'] !== 'All' ? item?.rowState?.orderData?.dropStateName === stateFilterOptions['stateName'] : true) &&
            (stateFilterOptions['readyDateFrom'] !== 'All' ? (item?.rowState?.orderData?.readyDate === undefined ? 0 : Number(`${moment(item?.rowState?.orderData?.readyDate).week()}`)) >= Number(stateFilterOptions['readyDateFrom']) : true) &&
            (stateFilterOptions['readyDateTo'] !== 'All' ? (item?.rowState?.orderData?.readyDate === undefined ? 1000 : Number(`${moment(item?.rowState?.orderData?.readyDate).week()}`)) <= Number(stateFilterOptions['readyDateTo']) : true)
        } else {
            ordersList = 
                (stateFilterOptions['pick'] !== 'All' ? item?.rowState?.orderData?.pickLocation === stateFilterOptions['pick'] : true) &&
                (stateFilterOptions['drop'] !== 'All' ? item?.rowState?.orderData?.dropLocation === stateFilterOptions['drop'] : true) &&
                (stateFilterOptions['del'] !== 'All' ? resource === 'outboundload' && item?.rowState?.orderData?.outDeliverNummber === stateFilterOptions['del'] : true) &&
                (stateFilterOptions['apptStatus'] !== 'All' ? 
                    Number(item?.rowState?.orderData?.outboundAppointmentStatus) === Number(stateFilterOptions['apptStatus']) &&
                    !!item?.rowState?.orderData?.outboundDropAppointment : true) &&
                (stateFilterOptions['stateName'] !== 'All' ? item?.rowState?.orderData?.dropStateName === stateFilterOptions['stateName'] : true) &&
                (stateFilterOptions['readyDateFrom'] !== 'All' ? (item?.rowState?.orderData?.readyDate === undefined ? 0 : Number(`${moment(item?.rowState?.orderData?.readyDate).week()}`)) >= Number(stateFilterOptions['readyDateFrom']) : true) &&
                (stateFilterOptions['readyDateTo'] !== 'All' ? (item?.rowState?.orderData?.readyDate === undefined ? 1000 : Number(`${moment(item?.rowState?.orderData?.readyDate).week()}`)) <= Number(stateFilterOptions['readyDateTo']) : true)
            }
        return ordersList;
    }), [resource, stateFilterOptions, stateLoadsAssignedOrders])

    const onlyOrdersLoadIds = filteredOrdersList?.map(order => order?.rowState?.loadData?.id);
    const filteredLoads = stateLoadsAssignedOrders?.filter(item => !item?.rowState?.orderData?.id && onlyOrdersLoadIds?.includes(item.rowState?.loadData?.id))

    let filteredData = [];
    for (let i = 0; i < filteredLoads?.length; i++) {
        filteredData.push(filteredLoads[i])
        for (let j = 0; j < filteredOrdersList.length; j++) {
            const orderId = filteredOrdersList[j]?.rowState?.loadObjectId;
            const loadId = filteredLoads[i]?.rowState?.loadId;
            if (orderId === loadId) {
                filteredData.push(filteredOrdersList[j])
            }
        }
    }

    const handleSelectChange = (option, filterType) => {
        if (filterType === 'default') {
            filterInOutOrders(resource, filterType);
        }
        filterInOutOrders(resource, filterType, option);
    };

    const handleSelectFilterUnassignedOrders = (option, filterType) => {
        if (filterType === 'default') {
            filterUnassignedOrders(resource, filterType);
        }
        filterUnassignedOrders(resource, filterType, option);
    };

    const displayLoadsAssignedOrders = useMemo(() =>
        !filteredData || filteredData === 0
            // eslint-disable-next-line react-hooks/exhaustive-deps
            ? [createSeparator(defRowHeight, state[resource].headers, 'load', 0)] : filteredData, [stateLoadsAssignedOrders, stateFilterOptions]);
    const displayRegionsAvailableOrders =
        !state[resource].regionsAvailableOrders || state[resource].regionsAvailableOrders.length === 0
            ? [createSeparator(defRowHeight, state[resource].headers, 'region', 0)] : state[resource].regionsAvailableOrders;
    
    const loadNumber = resource === 'load' ? 'inboundLoadNumber' : 'outboundLoadNumber';

    const loadsAssignedOrders = useMemo(() => displayLoadsAssignedOrders
        .filter(order => onlyTBDOrders ? 
            order.ordersData?.filter(order => order.rowState?.orderData?.isTbd).length > 0 ||
            order?.rowState?.orderData?.isTbd : order.rowState)
        .filter(order => order.rowState.visible)
        .filter(order => (order?.ordersData?.filter(order => 
                        order.rowState?.orderData?.[loadNumber]?.toLowerCase().includes(filterInput) ||
                        order.rowState?.orderData?.poNumber?.toLowerCase().includes(filterInput) ||
                        order.rowState?.orderData?.soNumber?.toLowerCase().includes(filterInput)).length > 0) ||
                (order.ordersData?.length === 0 && !filterInput) ||
                (order.ordersData?.length === 0 && order.rowState?.loadData?.loadNumber?.toLowerCase().includes(filterInput)) ||
                order.rowState?.orderData?.[loadNumber]?.toLowerCase().includes(filterInput) ||
                order.rowState?.orderData?.poNumber?.toLowerCase().includes(filterInput) ||
                order.rowState?.orderData?.soNumber?.toLowerCase().includes(filterInput))
        
        ,[displayLoadsAssignedOrders, onlyTBDOrders, filterInput, loadNumber]
    );

    const regionsAvailableOrders = useMemo(() => displayRegionsAvailableOrders
        .filter(order => onlyTBDOrders ? 
            order.ordersData?.filter(order => order.rowState?.orderData?.isTbd).length > 0 ||
            order?.rowState?.orderData?.isTbd : order.rowState)
        .filter(order => order.rowState.visible)
        .filter(order => (order?.ordersData?.filter(order => 
                    order.rowState?.orderData?.[loadNumber]?.toLowerCase().includes(filterInput) ||
                    order.rowState?.orderData?.poNumber?.toLowerCase().includes(filterInput) ||
                    order.rowState?.orderData?.soNumber?.toLowerCase().includes(filterInput)).length > 0) ||
            (order.ordersData?.length === 0 && !filterInput) ||
            (order.ordersData?.length === 0 && order.rowState?.loadData?.loadNumber?.toLowerCase().includes(filterInput)) ||
            order.rowState?.orderData?.[loadNumber]?.toLowerCase().includes(filterInput) ||
            order.rowState?.orderData?.poNumber?.toLowerCase().includes(filterInput) ||
            order.rowState?.orderData?.soNumber?.toLowerCase().includes(filterInput))
        .filter(order => 
            stateFiltersUnassignedOrder?.region === 'All' ?  true :
            resource === 'load' ?
            order?.rowState?.orderData?.pickRegionCode === stateFiltersUnassignedOrder?.region ||
            order?.rowState?.id.includes(stateFiltersUnassignedOrder?.region) 
            :
            order?.rowState?.orderData?.dropRegionCode === stateFiltersUnassignedOrder?.region ||
            order?.rowState?.id.includes(stateFiltersUnassignedOrder?.region)
        )
        ,[displayRegionsAvailableOrders, onlyTBDOrders, filterInput, loadNumber, stateFiltersUnassignedOrder, resource]
    );

    useEffect(() => {
        if (listHeaderReference && listHeaderReference?.current && listHeaderReference?.current?.scrollLeft) {
            listFirstReference.current.scrollLeft = listHeaderReference?.current?.scrollLeft
        }
        if (listHeaderReference && listHeaderReference?.current && listHeaderReference?.current?.scrollLeft) {
            listSecondReference.current.scrollLeft = listHeaderReference?.current?.scrollLeft
        }
    }, [filterInput]);

    useEffect(() => {
        if (listHeaderReference && listHeaderReference?.current && listHeaderReference?.current?.scrollLeft &&
            loadsAssignedOrders?.length < 3) {
            listHeaderReference.current.scrollLeft = listFirstReference.current.scrollLeft;
            mainScroll.current.scrollLeft = 0
        }
    }, [loadsAssignedOrders]);

    const getAsignedItemSize = useCallback(
        index => loadsAssignedOrders[index] ? loadsAssignedOrders[index].rowState.height : defRowHeight,
        [loadsAssignedOrders]);

    const getAvailableItemSize = useCallback(
        index => regionsAvailableOrders[index] ? regionsAvailableOrders[index].rowState.height : defRowHeight,
        [regionsAvailableOrders]);

    useEffect(() => {
        // assigned
        const selectedOrderAssigned = displayLoadsAssignedOrders.filter(order =>
            order.rowState?.orderData?.poNumber?.toLowerCase().includes(searchInput) &&
            searchInput !== ''
        );
        const selectedOrderAssignedIndex = displayLoadsAssignedOrders.map((order, index) => {
            if (order.rowState?.orderData?.poNumber?.toLowerCase().includes(searchInput) && searchInput !== '') {
                return index
            }
        }).filter(item => item !== undefined)[0];

        // available
        const selectedOrderAvailable = displayRegionsAvailableOrders.filter(order =>
            order.rowState?.orderData?.poNumber?.toLowerCase().includes(searchInput) &&
            searchInput !== ''
        );
        const selectedOrderAvailableIndex = displayRegionsAvailableOrders.map((order, index) => {
            if (order.rowState?.orderData?.poNumber?.toLowerCase().includes(searchInput) && searchInput !== '') {
                return index
            }
        }).filter(item => item !== undefined)[0];

        if (selectedOrderAssigned.length) {
            setSelectedOrder(selectedOrderAssigned)    
            if (assignedRef?.current?.scrollToItem) {
                const loadsList = displayLoadsAssignedOrders.filter(order => order.rowState.type === 'load');
                const selectedOrderId = selectedOrderAssigned[0].rowState.orderObjectId;
                let selectedLoadIndex = 0;
                for (let i = 0; i < loadsList.length; i++) {
                    for (let j = 0; j < loadsList[i].ordersData.length; j++) {
                        if (loadsList[i].ordersData[j].rowState.orderObjectId === selectedOrderId) {
                            selectedLoadIndex = i;
                        }
                    }
                }
                const finalIndex = Math.round(selectedOrderAssignedIndex && selectedOrderAssignedIndex + selectedLoadIndex );
                selectedOrderAssignedIndex && assignedRef.current.scrollToItem(finalIndex)
            };
        } else if (selectedOrderAvailable.length) {
            setSelectedOrder(selectedOrderAvailable)    
            if (availableRef?.current?.scrollToItem) {
                const regionsList = displayRegionsAvailableOrders.filter(order => order.rowState.type === 'region');
                const selectedOrderId = selectedOrderAvailable[0].rowState.orderObjectId;
                let selectedRegionIndex = 0;
                for (let i = 0; i < regionsList.length; i++) {
                    for (let j = 0; j < regionsList[i].ordersData.length; j++) {
                        if (regionsList[i].ordersData[j].rowState.orderObjectId === selectedOrderId) {
                            selectedRegionIndex = i;
                        }
                    }
                }
                const finalIndex = Math.round(selectedOrderAvailableIndex && selectedOrderAvailableIndex + selectedRegionIndex );
                selectedOrderAvailableIndex && availableRef.current.scrollToItem(finalIndex)
            };
        } else {
            setSelectedOrder('')
            // availableRef?.current && availableRef.current.scrollToItem && availableRef.current.scrollToItem(0);
            // assignedRef?.current && assignedRef.current.scrollToItem && assignedRef.current.scrollToItem(0)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assignedRef, availableRef, searchInput]);

    const RenderAssignedRow = useCallback(({ data, index, style }) => (
        (index !== undefined && data[index])
            ? <Row
                listRef={assignedRef}
                data={data[index]}
                itemSize={getAsignedItemSize(index)}
                style={style}
                dndRowIndex={index}
                startOnScroll={startOnScroll}
                resource={resource}
                onDrugStartMouseX={onDrugStartMouseX}
            />
            : null
    ), [getAsignedItemSize, resource
    ]);

    const RenderAvailableRow = useCallback(({ data, index, style }) => (
        (index !== undefined && data[index]) ?
            <Row
                listRef={availableRef}
                data={data[index]}
                itemSize={getAvailableItemSize(index)}
                style={style}
                dndRowIndex={index}
                startOnScroll={startOnScroll}
                resource={resource}
                onDrugStartMouseX={onDrugStartMouseX}
            />
            : <div style={{ height: '18px' }}>No pending orders</div>
    ), [getAvailableItemSize, resource
    ]);

    const SelectableComponent = useMemo(() => createSelectable(Row), []);

    const SelectableAssignedRow = useCallback(({ data, index, style }) => {
        return index !== undefined && data[index]
            ? <SelectableComponent
                selectableKey={data[index]}
                listRef={assignedRef}
                data={data[index]}
                selectedOrder={selectedOrder}
                itemSize={getAsignedItemSize(index)}
                style={style}
                dndRowIndex={index}
                startOnScroll={startOnScroll}
                resource={resource}
                onDrugStartMouseX={onDrugStartMouseX}
            />
            : null
    }, [getAsignedItemSize, resource, selectedOrder]);

    const SelectableAvailableRow = useCallback(({ data, index, style }) => {
        return index !== undefined && data[index]
            ? <SelectableComponent
                selectableKey={data[index]}
                listRef={availableRef}
                data={data[index]}
                selectedOrder={selectedOrder}
                itemSize={getAvailableItemSize(index)}
                style={style}
                dndRowIndex={index}
                startOnScroll={startOnScroll}
                resource={resource}
                onDrugStartMouseX={onDrugStartMouseX}
            />
            : <div style={{ height: '18px' }}>No pending orders</div>
    }, [getAvailableItemSize, resource, selectedOrder]);

    const handleMouseMoveSelectionAvailable = (itemData, isSelected) => {
        if (itemData.key.rowState.type === 'order') { 
            isSelected ? 
                itemData.domNode.style.backgroundColor = 'lightGray' :
                itemData.domNode.style.backgroundColor = 'inherit';
        }
    };

    const handleMouseEndSelectionAvailable = useCallback(selectedItems => {
        reactOnAvailableItems(resource, selectedItems.filter(i => i.rowState.type === 'order').map(i => i.rowState.id), 'order');
    }, [reactOnAvailableItems, resource]);

    const handleMouseEndSelectionAssigned = useCallback(selectedItems => {
        reactOnAssignedItems(resource, selectedItems.filter(i => i.rowState.type === 'order').map(i => i.rowState.id), 'order');
    }, [reactOnAssignedItems, resource]);

    const getSelectedOrders = useCallback(result => {
        const druggedOrder = result.source.droppableId === 'availableOrders'
            ?
            regionsAvailableOrders[result.source.index] :
            loadsAssignedOrders[result.source.index];

        return [...regionsAvailableOrders.filter(
            o => (o.rowState.type === 'order' && o.rowState.reacted) || o.rowState.id === druggedOrder.rowState.id),
            ...loadsAssignedOrders.filter(o => (o.rowState.type === 'order' && o.rowState.reacted) || o.rowState.id === druggedOrder.rowState.id)]
            .filter(o => !o.rowState.isSub && (!o.rowState.loadId || o.rowState.subId === undefined));

    }, [regionsAvailableOrders, loadsAssignedOrders]);

    const getCloneXPosition = cloneWidth => {
        let left = 0;
        const cloneHalfeWidth = cloneWidth / 2;
        if (onDrugStartMouseX.current) {
            left = onDrugStartMouseX.current - cloneHalfeWidth;
        }
        if (left < 0) {
            return 0;
        }
        return left;
    };

    const renderClone = useCallback((provided, snapshot, rubric) => {
        const cloneWidth = 400;
        provided.draggableProps.style = {
            ...provided.draggableProps.style, ...{
                backgroundColor: '#FFF',
                border: '1px solid #000',
                width: cloneWidth,
                height: '200px',
                color: '#000',
                fontSize: '14px',
                padding: '10px',
                left: getCloneXPosition(cloneWidth)

            }
        };
        const selectedOrders = getSelectedOrders(rubric);
        return (
            <div
                className='draggable-selected-orders-status__container'
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                ref={provided.innerRef}>
                <ul>
                    <li>
                        Drop Status: <span style={{ fontStyle: 'bold' }} ref={overIndex}></span>
                    </li>
                    <li>
                        Selected Orders Count: <span style={{ color: 'blue' }}>{selectedOrders.length}</span>
                    </li>
                    <li>
                        Selected Order POs: <span style={{ color: 'blue' }}>
                            {selectedOrders.map(so => so.rowState.orderData.poNumber).join(',')}
                        </span>
                    </li>
                </ul>
            </div>
        );
    }, [getSelectedOrders]);

    const first = useMemo(() => loadsAssignedOrders &&
        <AutoSizer disableWidth key='second'>
            {({ height }) => (
                <SelectableGroup onDuringSelection={handleMouseMoveSelectionAvailable} onEndSelection={handleMouseEndSelectionAssigned} tolerance={10} useCtrlKey>
                    <Droppable key='first' droppableId="assignedOrders" mode="virtual" direction="vertical"
                        renderClone={renderClone}>
                        {(provided, snapshot) => {
                            const refHanlder = el => {
                                provided.innerRef(el);
                                listFirstReference.current = el;
                            }
                            return (
                                <VariableSizeList
                                    ref={assignedRef}
                                    outerRef={refHanlder}
                                    style={{ overflowX: 'hidden', overflowY: 'scroll' }}
                                    itemData={loadsAssignedOrders}
                                    itemCount={snapshot.isUsingPlaceholder ? loadsAssignedOrders.length + 1 : loadsAssignedOrders.length}
                                    height={height}
                                    itemSize={getAsignedItemSize}>
                                    {selectable ? SelectableAssignedRow : RenderAssignedRow}
                                </VariableSizeList>
                            );
                        }}
                        </Droppable>
                </SelectableGroup>
            )}
        </AutoSizer>
        , [loadsAssignedOrders, getAsignedItemSize, RenderAssignedRow, renderClone, selectable, SelectableAssignedRow,
            handleMouseEndSelectionAssigned
            ]);

    const second = useMemo(() => regionsAvailableOrders &&
        <AutoSizer disableWidth key='second'>
            {({ height }) => (
                <SelectableGroup onDuringSelection={handleMouseMoveSelectionAvailable} onEndSelection={handleMouseEndSelectionAvailable} tolerance={10} useCtrlKey>  
                    <Droppable key='second' droppableId="availableOrders" mode="virtual" direction="vertical"
                        renderClone={renderClone}>
                        {(provided) => {
                            const refHanlder = el => {
                                provided.innerRef(el);
                                listSecondReference.current = el;
                            }
                            const ordersCount = regionsAvailableOrders.length;
                            return (
                                <VariableSizeList
                                    ref={availableRef}
                                    outerRef={refHanlder}
                                    innerRef={provided.innerRef}
                                    style={{ overflowX: 'hidden', overflowY: ordersCount !== 1 ? 'scroll' : 'auto' }}
                                    itemData={regionsAvailableOrders}
                                    itemCount={ordersCount === 1 ? ordersCount + 1 : ordersCount}
                                    height={height}
                                    itemSize={getAvailableItemSize}>
                                    {selectable ? SelectableAvailableRow : RenderAvailableRow}
                                </VariableSizeList>
                            );
                        }}
                        </Droppable>
                </SelectableGroup>
            )}
        </AutoSizer>
        , [regionsAvailableOrders,
            getAvailableItemSize,
            RenderAvailableRow,
            renderClone,
            selectable,
            SelectableAvailableRow,
            handleMouseEndSelectionAvailable
        ]);


    const draggedOrdersCondition = result => {
        if (result.destination) {
            const isUnAssign = result.destination.droppableId === 'availableOrders';
            if (isUnAssign) {
                return ['unassign', null, result.destination.index];
            }
            else {
                //going to find load
                const destinationIndexDelta = 1;
                let resultDestinationIndex = result.destination.index
                if (result.source.droppableId === 'assignedOrders' && result.destination.index > result.source.index) {
                    ++resultDestinationIndex;
                }
                let loadId = null;
                const destinationObject = loadsAssignedOrders[resultDestinationIndex - destinationIndexDelta];
                if (resultDestinationIndex !== 0) {
                    if (!destinationObject.rowState) {
                        return ['no_destination', null, null];
                    }
                    if (destinationObject.rowState.isSub) {
                        return ['no_destination', null, null];
                    }
                    if (destinationObject.rowState.type === 'load') {
                        loadId = destinationObject.rowState.id;
                    }
                    else if (destinationObject.rowState.type === 'order') {
                        loadId = destinationObject.rowState.loadId;
                    }
                    else if (destinationObject.rowState.type === 'separator') {
                        loadId = null;
                    }
                }
                const load = loadsAssignedOrders.find(l => l.rowState.type === 'load' && l.rowState.id === loadId);
                if (loadId && destinationObject.rowState.loadData.message) {
                    return ['assign', load, resultDestinationIndex, 'newLoad'];
                } else 
                if (loadId) {
                    return ['assign', load, resultDestinationIndex];
                } else {
                    return ['new_assign', null, resultDestinationIndex];
                }
            }
        }
        return ['no_destination', null, null];
    };

    const onDragUpdate = result => {
        const draggedCondition = draggedOrdersCondition(result);
        switch (draggedCondition[0]) {
            case 'unassign':
                overIndex.current.innerHTML = 'Orders will be unassigned';
                break;
            case 'assign':
                overIndex.current.innerHTML = `Orders will be assigned to load ${draggedCondition[1].rowState.loadData.loadNumber}`;
                break;
            case 'new_assign':
                overIndex.current.innerHTML = 'Orders will be assigned to NEW load';
                break;
            case 'no_destination':
            default:
                overIndex.current.innerHTML = '';
                break;
        }
    };

    const onDragEnd = result => {
        const draggedCondition = draggedOrdersCondition(result);
        const selectedOrders = getSelectedOrders(result);
        let loadHasSubOrder = false;
        let steps = 0;
        if (resource === 'outboundload') {
            selectedOrders.forEach(order => {
                const draggableLoadId = draggedCondition[1]?.rowState?.loadId;
                const selectedOrderLoadId = order?.rowState?.loadObjectId;
                const ordersInDraggableLoad = draggableLoadId ? state.outboundload.loadsAssignedOrders.filter(order => order.rowState.loadObjectId === draggableLoadId) : [];
                const ordersInSelectedLoad = state.outboundload.loadsAssignedOrders.filter(order => order.rowState.loadObjectId === selectedOrderLoadId)
                const allOrders = [...ordersInDraggableLoad, ...ordersInSelectedLoad]
                allOrders.forEach(order => {
                    if (order.rowState.subId && steps < 1) {
                        loadHasSubOrder = true;
                        steps = steps + 1;
                        message.error("It's impossible to assign/unassign orders because the load has a subLoad");
                    }
                })
            })
        }

        // can not assign the order to anywhere when the order has PickIn
        const orderHasOutboundPickIn = resource === 'load' ? 
            selectedOrders?.filter(order => order.rowState.orderData.inboundPickIn).length > 0 :
            selectedOrders?.filter(order => order.rowState.orderData.outboundPickIn).length > 0;
        if (orderHasOutboundPickIn) {
            message.error('The order is impossible to unassign as it has a Pick In date');
        };
        
        switch (draggedCondition[0]) {
            case 'unassign':
                if (result.destination.droppableId === 'availableOrders' && 
                        result.source.droppableId === 'assignedOrders' &&
                        !orderHasOutboundPickIn &&
                        !loadHasSubOrder) {
                    props.unAssignOrders(resource, selectedOrders, allAvailableOrders);
                }
                break;
            case 'assign':
                // can not assign the order if we try  to assign to the same load
                const isOtherLoad = draggedCondition[1].ordersData.find(order => order.rowState?.id === result.draggableId) === undefined;
                if (isOtherLoad && !orderHasOutboundPickIn && !loadHasSubOrder) {
                    setDraggableLoad(draggedCondition[1]);
                    setAssignedOrders(selectedOrders);
                    props.assignOrdersToLoad(resource, draggedCondition[1], selectedOrders, allAvailableOrders, draggedCondition[3]);
                }
                break;
            case 'new_assign':
                props.assignOrdersToNewLoad(resource, selectedOrders, draggedCondition[2], allAvailableOrders);
                break;
            case 'no_destination':
            default:
                //nothing for now
                break;
        }
        //setIsStartDrag(false);
    };

    const swapContainers = () => {
        return swap ? [second, first] : [first, second];
    } 

    const content = (
        <div className='main-and-filter__container'>
            <Filters
                state={state}
                resource={resource} 
                handleSelectChange={handleSelectChange}
                handleSelectFilterUnassignedOrders={handleSelectFilterUnassignedOrders}
                setSearchInput={setSearchInput}
                searchInput={searchInput}
                setFilterInput={setFilterInput}
                onlyTBDOrders={onlyTBDOrders}
                setOnlyTBDOrder={setOnlyTBDOrder}
                isBookingPage={isBookingPage}
            />
            <div className='main-data__container'>
                <div style={{
                    display: 'flex'
                }}>
                    {header}
                    <div style={{
                        width: `${scrollSizeSmall}px`,
                        height: '32px',
                        backgroundColor: 'lightgrey',
                        display: 'flex'
                    }} />
                </div>
                <div style={{ flexGrow: 1 }}>
                    <DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragUpdate}>
                        <Split
                            sizes={isBookingPage ? [1, 0] : [50, 50]}
                            direction={isBookingPage ? "horizontal" : 'vertical'}
                            gutterSize={isBookingPage ? 0 : 12}>
                            {swapContainers()}
                        </Split>
                    </DragDropContext>
                </div>
                <div style={{
                    backgroundColor: 'red',
                    width: '100%',
                    height: `${scrollSize}px`,
                    overflowX: 'scroll',
                    flexShrink: 0,
                }}
                    ref={mainScroll}
                    id='main'
                    onScroll={e => onScrollX(e)}>
                    <div className='resp-table-row'>
                        <div
                            className='table-body-cell'
                            style={{
                                maxWidth: `${width}px`,
                                width: `${width}px`,
                                minWidth: `${width}px`,
                                boxSizing: 'border-box',
                                backgroundColor: 'black'
                            }} />
                    </div>
                
                </div>
            </div>
            {assignOrdersModalIsOpen && 
                <AssignOrdersModal
                    assignOrdersModalIsOpen={assignOrdersModalIsOpen}
                    setAssignOrdersModalIsOpen={setAssignOrdersModalIsOpen}
                    resource={resource}
                    allAvailableOrders={allAvailableOrders}
                    assignedOrders={assignedOrders}
                    draggableLoad={draggableLoad}>
            </AssignOrdersModal>}
        </div>
    );

    return <>
            <ToolBar 
                mainResource={resource} 
                resource={resource} 
                filter 
                setOnlyTBDOrder={setOnlyTBDOrder}
                isBookingPage={isBookingPage}
                setSearchInput={setSearchInput}
                setFilterInput={setFilterInput}
                searchInput={searchInput}
            />
                {loading || loadingLocations ? <Loading /> : content}
            </>;
};

export default connect(state => ({
    state: state
}), dispatch => ({
    load: (resource, loadingType) => dispatch(load(resource, loadingType)),
    reactOnAvailableItems: (resource, ids, type) => dispatch(reactOnAvailableItems(resource, ids, type)),
    reactOnAssignedItems: (resource, ids, type) => dispatch(reactOnAssignedItems(resource, ids, type)),
    unAssignOrders: (resource, orders, allAvailableOrders) => dispatch(unAssignOrders(resource, orders, allAvailableOrders)),
    assignOrdersToLoad: (resource, load, orders, allAvailableOrders, newLoad) => dispatch(assignOrdersToLoad(resource, load, orders, allAvailableOrders, newLoad)),
    assignOrdersToNewLoad: (resource, orders, index, allAvailableOrders) => dispatch(assignOrdersToNewLoad(resource, orders, index, allAvailableOrders)),
    resetError: resource => dispatch(resetError(resource)),
    filterInOutOrders: (resource, filterType, option) => dispatch(filterInOutOrders(resource, filterType, option)),
    filterUnassignedOrders: (resource, filterType, option) => dispatch(filterUnassignedOrders(resource, filterType, option)),
    getLocations: (resource, param) => dispatch(getLocations(resource, param)),
}))(Spreadsheet);