import React, { useEffect, useRef, useCallback, useMemo, useState } from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';
import { Dropdown, Space, message, Menu } from 'antd';
import './index.css'
import HeaderRow from './headerRow';
import Row from './row';
import Loading from "../../screen/loading";
import { getCustomerOrderRejected, sortBy } from '../../../../store/actions/customerRejectedOrders/customerRejectedOrders';
import { getContentWidth } from '../../../../store/reducers/customerRejectedOrders/helper/general';

const CustomeRejectedOrders = props => {
    const { getCustomerOrderRejected, loading, haseError, resetError, actionMessage, state, total, sortBy } = props;
    const resource = 'customerRejectedOrders';
    const defRowHeight = 40;
    const scrollSize = 0;
    const rejectedOrdersRef = useRef(null);
    const listReference = useRef(null);
    const pallInSummaryReference = useRef(null);
    const listHeaderReference = useRef(null);
    const rejectedOrdersHeaderReference = useRef(null);
    const mainScroll = useRef(null);
    const startOnScroll = width => mainScroll.current.scrollLeft = width;
    const headers = state[resource].headers;
    const width = getContentWidth(headers) + scrollSize;
    const innerWidth = window.innerWidth;
    const smallScreen = width > innerWidth;
    const [sortValue, setSortValue] = useState('');

    const onScrollX = e => {
        if (listHeaderReference.current && listReference.current && e.target) {
            listHeaderReference.current.scrollLeft =
            listReference.current.scrollLeft =
            e.target.scrollLeft;
        }
        if (rejectedOrdersHeaderReference.current && listReference.current && e.target) {
            rejectedOrdersHeaderReference.current.scrollLeft =
            listReference.current.scrollLeft =
            e.target.scrollLeft;
        }
        if (pallInSummaryReference.current && listReference.current && e.target) {
            pallInSummaryReference.current.scrollLeft =
            listReference.current.scrollLeft =
            e.target.scrollLeft;
        }
    }

    useEffect(() => {
        getCustomerOrderRejected('CustomerOrder', 'myrejectedorderorderlist');
    }, []);

    useEffect(() => {
        if (haseError) {
            message.error(actionMessage);
            resetError(resource);
        }
    }, [haseError, actionMessage, resetError, resource]);

    let myCustomerRejectedOrders = [];

    if (sortValue) {
        myCustomerRejectedOrders = state[resource]?.sortedCustomerRejectedOrders;
    } else {
        myCustomerRejectedOrders = state[resource]?.customerRejectedOrders;
    };

    const header = useMemo(() => <div ref={listHeaderReference} style={{
        width: `calc(100% - 2px)`,
        overflow: 'hidden',
        borderRadius: '12px 12px 0 0',
        borderLeft: '1px solid #29CABA',
    }}>
        <HeaderRow resource={resource} />
    </div>, [resource]);

    const getMyCustomerRejectedOrdersItemSize = useCallback(
        index => myCustomerRejectedOrders?.[index] ? myCustomerRejectedOrders[index].rowState.height : defRowHeight,
        [myCustomerRejectedOrders]);

    const RenderRejectedOrdersRow = useCallback(({ data, index, style }) => (
        (index !== undefined && data[index])
            ? <Row
                listRef={rejectedOrdersRef}
                data={data[index]}
                itemSize={getMyCustomerRejectedOrdersItemSize(index)}
                style={style}
                resource={resource}
                startOnScroll={startOnScroll}
                index={index}
            />
            : null
    ), [getMyCustomerRejectedOrdersItemSize, resource]);

    const allMyCustomerRejectedOrders = useMemo(() => myCustomerRejectedOrders &&
    <AutoSizer disableWidth>
        {({ height }) => (
            <>
                <VariableSizeList
                    ref={rejectedOrdersRef}
                    outerRef={el => { listReference.current = el }}
                    style={{
                        overflowX: 'hidden',
                        overflowY: 'auto',
                        borderBottom: '1px solid #dedede',
                        borderLeft: '1px solid #dedede',
                        borderRight: '1px solid #dedede',
                        borderRadius: '0 0 12px 12px',
                        boxShadow: 'black 6px 6px 0 -12px, 0px 6px 6px -8px black',
                    }}
                    itemData={myCustomerRejectedOrders}
                    itemCount={myCustomerRejectedOrders.length}
                    height={myCustomerRejectedOrders && (myCustomerRejectedOrders.length * 29) > height ? height-5 : myCustomerRejectedOrders.length * 28.1}
                    itemSize={getMyCustomerRejectedOrdersItemSize}>
                    {RenderRejectedOrdersRow}
                </VariableSizeList>
            </>)}
    </AutoSizer>
    , [myCustomerRejectedOrders, getMyCustomerRejectedOrdersItemSize, RenderRejectedOrdersRow]);

    const handleSelectChange = e => {
        sortBy('customerOrder', e.key);
        setSortValue(e.key);
    };
    
    const menu = (
        <Menu className='customer-order__menu' onClick={handleSelectChange}>
            <Menu.Item key="byDate">By date</Menu.Item>
            <Menu.Item key="byDirection">By direction</Menu.Item>
        </Menu>
    );
    
    const content = (<>
        <div className='customer-order__upper-header'>
            <div className='customer-order__upper-header__left rejected_orders_left'>
            <div className='customer-order__upper-header__sort'>
                <div className='sort_icon'></div>
                <Dropdown className='customer-order__dropdown' overlay={menu} placement="bottomLeft">
                    <span onClick={(e) => e.preventDefault()}>
                    <Space>Sort</Space>
                    </span>
                </Dropdown>
            </div>
            <separator className={'customerOrder__separator'} vertical=''></separator>
            <div className='customer-order__upper-header__orders-count_rejected'>
                Rejected Orders: <span>{total}</span>
            </div>
            </div>
            <div>
                <Link to="/customerorder" className='customer-order__upper-header_btn_rejected'>ACTIVE ORDERS</Link>
            </div>
        </div>
        <div className='headerContainer'>{header}</div>
        <div style={{ flexGrow: 1, width: smallScreen ? '100%' : width }}>{allMyCustomerRejectedOrders}</div>
        <div style={{
            width: 'calc(100% + 32px)',
            overflowX: 'scroll',
            flexShrink: 0,
            marginBottom: '6px',
            position: 'absolute',
            bottom: '2px',
            height: '7px',
        }}
            ref={mainScroll}
            onScroll={e => onScrollX(e)}>
            <div
                className='table-body-cell'
                style={{
                    maxWidth: `${width}px`,
                    width: `${width}px`,
                    minWidth: `${width}px`,
                }} />
        </div>
    </>);

    return <>{loading ? <Loading /> : content}</>;
};

const mapStateToProps = state => ({
    state: state,
    loading: state.customerRejectedOrders.loadingRejectedOrders,
    total: state.customerRejectedOrders.totalRejectedOrders
});

const mapDispatchToProps = (dispatch) => ({
    getCustomerOrderRejected: (resource, endPoint) => dispatch(getCustomerOrderRejected(resource, endPoint)),
    sortBy: (resource, sortValue) => dispatch(sortBy(resource, sortValue)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomeRejectedOrders);