import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { DatePicker, Tooltip } from 'antd';
import { enabled } from 'store/dist/store.legacy';
import { Defines } from '../../../../project/Defines';
import { setItemData, setItemDisplayState } from '../../../../store/actions/trafficControl/trafficControl';
import CustomModal from "../CustomerOrderInProgress/modal";
import CustomModalDelete from "./modalDelete";
import EditWarningModal from './modalEditWarning';

const Cell = ({
  state,
  cellData,
  itemSize,
  rowState,
  resource,
  startOnScroll,
  setItemData,
  setItemDisplayState,
}) => {
  const traffic = state[resource];
  const redonly = 1;
  const editable = 2;
  const header = traffic.headers.find(h => h.key === cellData.key);
  // const headerIndex = traffic.headers.findIndex(h => h.key === cellData.key);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [editWarningModaIsOpen, setEditWarningModaIsOpen] = useState(false);

  const width = header.width;
//   const offsetWidth = traffic.headers.reduce((r, h, index) => {
//     if (h.visible && index < (headerIndex - 3)) {
//         r += h.width;
//     }
//     return r;
// }, 0);

const lastSelectId = traffic.lastSelected?.id;
const lastSelectHeaderKey = traffic.lastSelected?.headerKey;
const lastSelectenable = traffic.lastSelected?.enable;
const rowStateId = rowState.orderId;
const headerKey = header.key;
const [displayState, setDisplayState] = useState(redonly);
const [tempData, setTempData] = useState(null);

useEffect(() => {
  if (lastSelectId === rowStateId && lastSelectHeaderKey === headerKey && lastSelectenable) {
      setDisplayState(enabled);
  }
}, [lastSelectId, lastSelectHeaderKey, lastSelectenable, rowStateId, headerKey]);

const cellStyle = cellData.cellStyle;
let data = (cellData.valueText && cellData.fieldType === 'text') ? cellData.valueText : cellData.value;
  useEffect(() => {
    setTempData(data);
  }, [data]);

  const handleEdit = () => {
    setIsOpen(true);
  };

  const handleDelete = () => {
    setDeleteModalIsOpen(true);
  };

  const inputReference = useRef(null);

  const dataCompare = (newData, data) => {
    switch (cellData.fieldType) {
      case 'date':
      case 'dateTime':
        if (newData instanceof Date && data instanceof Date) {
          return newData.getTime() === data.getTime();
        }
        return newData === data;
      default:
        return newData === data;
    }
  };

  const setData = (value, subType) => {
    onSetBlur();
    let newValue = value;
    if (value !== '') {
      switch (cellData.fieldType) {
        case 'date':
          newValue = new Date(value);
          break;
        case 'dateTime':
          newValue = new Date(value);
          break;
        case 'text':
          newValue = value;
          break;
        case 'textInput':
          newValue = value;
          break;
        case 'select':
          newValue = value;
          break;
        case 'checkbox':
          newValue = value;
          break;
        default:
          newValue = value;
          break;
      }
    }
    const valueForCompare = tempData;
    if (!dataCompare(newValue, valueForCompare)) {
      setTimeout(() => {
          setItemData(resource, newValue, rowState, header, subType);
      }, 100);
      setTimeout(() => {
        setTempData(newValue);
      }, 100);
    }
  };

  useEffect(() => {
    if (inputReference && inputReference.current) {
      inputReference.current.focus();
    }
  }, [displayState]);

  const getData = () => {
    if (tempData !== '' && tempData !== null) {
      switch (cellData.fieldType) {
        case 'date':
          return moment(tempData).format(Defines.Format.PrintDate);
        case 'dateTime':
          if (cellData.key === 'dueDate') {
            const indicator = rowState?.orderData?.orderIndicator;
            if (rowState?.orderData?.isHardDate) {
              return (
                <Tooltip title='Hard Date'>
                  <span style={{ color: 'rgb(96, 0, 128)' }}>{tempData ? moment(tempData).format(Defines.Format.PrintDateLong) : ''}</span>
                </Tooltip>
              )
            } else if (indicator === 2) {
              return (
                <Tooltip title='Data Change'>
                  <span style={{ color: '#EF2112' }}>{tempData ? moment(tempData).format(Defines.Format.PrintDateLong) : ''}</span>
                </Tooltip>
              )
            } else {
              return (
                <Tooltip title='Standard'>
                  <span style={{ color: '#0000FF' }}>{tempData ? moment(tempData).format(Defines.Format.PrintDateLong) : ''}</span>
                </Tooltip>
              )
            }
          } else {
            return <>{tempData ? moment(tempData).format(Defines.Format.PrintDateLong) : ''}</>;
          }
        case 'text':
          if (header.key === 'lbs' && tempData === null) {
                return 0;
          } else return tempData;
        case 'actions':
          return (
            <div className='edit_delete_icons'>
              <div onClick={handleEdit}>
                <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.1965 6.02L12.1165 6.94L3.05647 16H2.13647V15.08L11.1965 6.02ZM14.7965 0C14.5465 0 14.2865 0.1 14.0965 0.29L12.2665 2.12L16.0165 5.87L17.8465 4.04C18.2365 3.65 18.2365 3.02 17.8465 2.63L15.5065 0.29C15.3065 0.09 15.0565 0 14.7965 0ZM11.1965 3.19L0.136475 14.25V18H3.88647L14.9465 6.94L11.1965 3.19Z" fill="#3C8680"/>
                </svg>
              </div>
              <div onClick={handleDelete}>
                <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.1365 6V16H3.13647V6H11.1365ZM9.63647 0H4.63647L3.63647 1H0.136475V3H14.1365V1H10.6365L9.63647 0ZM13.1365 4H1.13647V16C1.13647 17.1 2.03647 18 3.13647 18H11.1365C12.2365 18 13.1365 17.1 13.1365 16V4Z" fill="#FF0000"/>
                </svg>
              </div>
            </div>
          );
        default:
          return tempData;
      }
    }
    return tempData;
  };

  const getInput = () => {
    switch (cellData.fieldType) {
      case 'dateTime':
        return (
          <DatePicker
            open={true}
            ref={inputReference}
            onBlur={value => {
              setTimeout(() => {
                if (inputReference.current) {
                  setDisplayState(redonly);
                }
              }, 0);
              setData(value.currentTarget.value);
            }}
            onChange={value => {
              if (inputReference.current) {
                setDisplayState(redonly);
              }
              setData(value === null ? '' : value._d);
            }}
            suffixIcon={null}
            showTime
            className='spreadsheet-picker'
            value={tempData ? moment(tempData, Defines.Format.PrintDateTime) : ''}
            format={Defines.Format.PrintDateTime}
            onKeyDown={e => {
              if (e.key === 'Escape') {
                if (inputReference.current) {
                  setDisplayState(redonly);
                }
              }
            }}
            showToday={false}
          />
        );
      case 'date':
        return (
          <DatePicker
            open={true}
            ref={inputReference}
            onBlur={value => {
              if (inputReference.current) {
                setDisplayState(redonly);
              }
              setData(value.currentTarget.value);
            }}
            onChange={value => {
              if (inputReference.current) {
                setDisplayState(redonly);
              }
              setData(value === null ? '' : value._d);
            }}
            suffixIcon={null}
            className='spreadsheet-picker'
            value={tempData ? moment(tempData, Defines.Format.PrintDate) : ''}
            format={Defines.Format.PrintDate}
            onKeyDown={e => {
              if (e.key === 'Escape') {
                if (inputReference.current) {
                  setDisplayState(redonly);
                }
              }
            }}
            showToday={false}
          />
        );
      case 'textInput':
        return (
          <input
            defaultValue={tempData ? tempData : ''}
            ref={inputReference}
            onBlur={value => {
              setData(value.currentTarget.value);
              if (inputReference.current) {
                setTimeout(() => {
                  setDisplayState(redonly);
                }, 100);
              }
            }}
            format={Defines.Format.PrintDate}
            style={{ paddingLeft: '4px' }}
          />
        );
      default:
        return (
          <input
            style={{ backgroundColor: 'yellow', boxSizing: 'inherit', width: '100%' }}
            value={getData()}
            ref={inputReference}
            onChange={event => setData(event.target.value)}
            onBlur={() => {
              if (inputReference.current) {
                setDisplayState(redonly);
              }
            }}
          />
        );
    }
  };

  const dataDisplay = () => displayState === redonly ? getData() : getInput();

  const cellDefaultStyle = {
      maxWidth: `${width}px`,
      width: `${width}px`,
      minWidth: `${width}px`,
      height: `${itemSize}px`,
      color: cellData.color,
      padding: '4px',
  };

  const onSetFocus = () => {
    // startOnScroll && startOnScroll(offsetWidth);
      if (cellData.editable) {
          setDisplayState(editable);
      }
      setItemDisplayState(resource, rowState, header, cellData.editable);
  };

  const onSetBlur = () => {
      setItemDisplayState(resource, rowState, header, false);
  };

  const editableFunction = (cellData) => cellData.editable ? setDisplayState(editable) : null;

  return (
    <>
      <div  onFocus={() => onSetFocus()}
            onClick={() => { editableFunction(cellData)} }
            className='table-body-cell table-borders customer-order-row'
            style={{ ...cellDefaultStyle, ...cellStyle,
  }}
            tabIndex={displayState === redonly ? 0 : -1}>
          {dataDisplay()}
      </div>
      {modalIsOpen &&
        <CustomModal
            modalIsOpen={modalIsOpen}
            setIsOpen={setIsOpen}
            editMode
            data={rowState.orderData}
            setEditWarningModaIsOpen={setEditWarningModaIsOpen}
        />
      }
      {deleteModalIsOpen &&
        <CustomModalDelete
            deleteModalIsOpen={deleteModalIsOpen}
            setDeleteModalIsOpen={setDeleteModalIsOpen}
            id={rowState.orderData.id}
        />
      }
      {editWarningModaIsOpen &&
        <EditWarningModal
            editWarningModalIsOpen={editWarningModaIsOpen}
            setEditWarningModaIsOpen={setEditWarningModaIsOpen}
            setIsOpen={setIsOpen}
        />
      }
    </>
);
};

const mapStateToProps = state => ({
  state: state,
});

const mapDispatchToProps = dispatch => ({
  setItemData: (resource, value, rowState, header, subType) => dispatch(setItemData(resource, value, rowState, header, subType)),
  setItemDisplayState: (resource, rowState, header, enable) => dispatch(setItemDisplayState(resource, rowState, header, enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Cell);