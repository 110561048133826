import { CUSTOMERS_BUILD as actionTypes } from '../../actions/customers/customersActionTypes';
import * as customers from './customersReducer';

const defaultState = {
    headers: [],
    headersPricePerRange: [
        {
            key: 'pickLocation',
            title: 'PICK LOCATION',
            width: 180,
        },
        {
            key: 'dropLocation',
            title: 'DROP LOCATION',
            width: 180,
        },
        {
            key: '1',
            title: '1',
            width: 50,
        },
        {
            key: '2-4',
            title: '2-4',
            width: 50,
        },
        {
            key: '5-8',
            title: '5-8',
            width: 50,
        },
        {
            key: '9-12',
            title: '9-12',
            width: 50,
        },
        {
            key: '13-16',
            title: '13-16',
            width: 50,
        },
        {
            key: '17-20',
            title: '17-20',
            width: 50,
        },
        {
            key: 'tl',
            title: 'TL',
            width: 50,
        },
        {
            key: 'delete',
            title: '',
            width: 40,
        }
    ],
    headersPricePerPound: [
        {
            key: 'pickLocation',
            title: 'PICK LOCATION',
            width: 180,
        },
        {
            key: 'dropLocation',
            title: 'DROP LOCATION',
            width: 180,
        },
        {
            key: 'minPrice',
            title: 'MIN PRICE',
            width: 70,
        },
        {
            key: 'perWeight',
            title: 'PER POUND',
            width: 70,
        },
        {
            key: 'tl',
            title: 'TL',
            width: 50,
        },
        {
            key: 'delete',
            title: '',
            width: 40,
        }
    ],
    headersPricePerPall: [
        {
            key: 'pickLocation',
            title: 'PICK LOCATION',
            width: 180,
        },
        {
            key: 'dropLocation',
            title: 'DROP LOCATION',
            width: 180,
        },
        {
            key: '1',
            title: '1',
            width: 50,
        },
        {
            key: '2',
            title: '2',
            width: 50,
        },
        {
            key: '3',
            title: '3',
            width: 50,
        },
        {
            key: '4',
            title: '4',
            width: 50,
        },
        {
            key: '5',
            title: '5',
            width: 50,
        },
        {
            key: '6',
            title: '6',
            width: 50,
        },
        {
            key: '7',
            title: '7',
            width: 50,
        },
        {
            key: '8',
            title: '8',
            width: 50,
        },
        {
            key: '9',
            title: '9',
            width: 50,
        },
        {
            key: '10',
            title: '10',
            width: 50,
        },
        {
            key: '11',
            title: '11',
            width: 50,
        },
        {
            key: '12',
            title: '12',
            width: 50,
        },
        {
            key: '13',
            title: '13',
            width: 50,
        },
        {
            key: '14',
            title: '14',
            width: 50,
        },
        {
            key: '15',
            title: '15',
            width: 50,
        },
        {
            key: '16',
            title: '16',
            width: 50,
        },
        {
            key: '17',
            title: '17',
            width: 50,
        },
        {
            key: '18',
            title: '18',
            width: 50,
        },
        {
            key: '19',
            title: '19',
            width: 50,
        },
        {
            key: '20',
            title: '20',
            width: 50,
        },
        {
            key: 'tl',
            title: 'TL',
            width: 50,
        },
        {
            key: 'delete',
            title: '',
            width: 40,
        }
    ],
    lastSelected: {
        id: null,
        headerKey: null,
        enable: false
    },
    loading: true,
    hasError: false,
    message: '',
    customersList: null,
    customersPriceList: null,
    perPalletType: true,
};

export default (state = defaultState, action) => {
    const pathname = window.location.pathname;
    if (pathname === '/loadplanning/customerPrice/priceperrange') {
        state.headers = defaultState.headersPricePerRange;
    } else if (pathname === '/loadplanning/customerPrice/priceperpound') {
        state.headers = [...defaultState.headersPricePerPound];
    } else if (pathname === '/loadplanning/customerPrice/priceperpall') {
        state.headers = [...defaultState.headersPricePerPall];
    }

    switch (action.type) {
        case actionTypes.GET_CUSTOMERS_LIST_LOADING:
            return customers.setInLoading(state);
        case actionTypes.GET_CUSTOMERS_LIST:
            return customers.getCustomersList(state, action.data);
        case actionTypes.RELOAD_ON_ERROR_LOAD_DETAILS:
            return customers.getCustomersList(state, action.data.responce, true, action.data.errorText ? action.data.errorText : 'Something go wrong, data reloaded!');
        case actionTypes.GET_CUSTOMERS_LIST_ERROR:
            return customers.setError(state, action.data);
        case actionTypes.RESET_ERROR:
            return customers.resetError(state);
        case actionTypes.SET_DISPLAY_STATE:
            return customers.setDisplayState(state, action.data.rowState, action.data.header, action.data.enable);
        case actionTypes.GET_CUSTOMERS_PRICE_LIST_LOADING:
            return customers.setInLoading(state);
        case actionTypes.GET_CUSTOMERS_PRICE_LIST:
            return customers.getCustomersPriceList(state, action.data);
        case actionTypes.GET_CUSTOMERS_PRICE_LIST_ERROR:
            return customers.setError(state, action.data);
        case actionTypes.ADD_EMPTY_LINE_ON_PRICE_LIST:
            return customers.addEmptyLineOnPriceList(state, action.data);
        case actionTypes.REMOVE_EMPTY_LINES_ON_PRICE_LIST:
            return customers.removeEmptyLinesOnPriceList(state);
        case actionTypes.SET_CUSTOMER_PRICE_DATA:
            return customers.setPriceData(state, action.data.value, action.data.rowState, action.data.header);
        case actionTypes.ADD_CUSTOMER_PRICE_LOADING:
            return customers.setInLoading(state);
        case actionTypes.ADD_CUSTOMER_PRICE:
            return customers.addCustomersPrice(state, action.data);
        case actionTypes.ADD_CUSTOMER_PRICE_ERROR:
            return customers.setError(state, action.data);
        case actionTypes.SET_CUSTOMER_PRICE_TYPE:
            return customers.setcustomerPriceType(state, action.data);
        case actionTypes.SET_CUSTOMER_PRICE_TYPE_LOADING:
            return customers.setInLoading(state);
        case actionTypes.SET_CUSTOMER_PRICE_TYPE_ERROR:
            return customers.setError(state, action.data);
        case actionTypes.DELETE_CUSTOMER_PRICE_LOADING:
            return customers.setInLoading(state);
        case actionTypes.DELETE_CUSTOMER_PRICE_ERROR:
            return customers.setError(state, action.data);
        case actionTypes.SET_PER_PALLET_TYPE:
            return customers.setPerPalletType(state, action.data);
        case actionTypes.GET_CUSTOMER_LOCATIONS_LIST_LOADING:
            return customers.setInLoading(state);
        case actionTypes.GET_CUSTOMER_LOCATIONS_LIST:
            return customers.setLocationsList(state, action.data);
        case actionTypes.GET_CUSTOMER_LOCATIONS_LIST_ERROR:
            return customers.setError(state, action.data);
        default:
            return state;
    }
};