import React, { useEffect, useRef, useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';
import { Dropdown, Space, Menu, message } from 'antd';
import './index.css';
import HeaderRow from './headerRow';
import Row from './row';
import Loading from '../../screen/loading';
import { customerOrderInProgress, sortBy } from '../../../../store/actions/customerOrderInProgress/customerOrderInProgress';
import { resetError, setFormValues, resetOrderAdded } from '../../../../store/actions/customerOrderNewOrders/customerOrderNewOrders';
import { getContentWidth } from '../../../../store/reducers/customerOrderInProgress/helper/general';
import CustomModal from "./modal";
import ErrorModal from './errorModal';

const CustomerOrderInProgressSpreadsheet = props => {
  const { 
    customerOrderInProgress, 
    sortBy, 
    loading, 
    state, 
    total, 
    addCustomerOrderloading,
    hasError,
    resetError,
    actionMessage,
    orderAdded,
    setFormValues,
    formValues,
    resetOrderAdded,
    totalUpcomingOrders,    
    } = props;
  const resource = 'customerOrderInProgress';
  const defRowHeight = 40;
  const scrollSize = 0;
  const customerOrderRef = useRef(null);
  const listReference = useRef(null);
  const listHeaderReference = useRef(null);
  const mainScroll = useRef(null);
  const startOnScroll = width => (mainScroll.current.scrollLeft = width);
  const headers = state[resource].headers;
  const width = getContentWidth(headers) + scrollSize;
  const [sortValue, setSortValue] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [errorModalText, setErrorModalText] = useState('');
  const [saveBtnClicked, setSaveBtnClicked] = useState(false);
  const [saveAndNewBtnClicked, setSaveAndNewBtnClicked] = useState(false);
  const [saveAndCloneBtnClicked, setSaveAndCloneBtnClicked] = useState(false);

  const onScrollX = e => {
    if (listHeaderReference.current && listReference.current && e.target) {
      listHeaderReference.current.scrollLeft = listReference.current.scrollLeft = e.target.scrollLeft;
    }
  };

  useEffect(() => {
    if (hasError) {
      setErrorModalText(actionMessage);
    }
  }, [hasError, actionMessage, resetError, resource]);

  useEffect(() => {
    if (orderAdded) {
        message.success('Order successfully saved', 3);
        saveBtnClicked && setIsOpen(false);
        saveBtnClicked && resetError(resource);
        saveBtnClicked && setFormValues({});
        saveAndNewBtnClicked && setFormValues({});
        const updatedValues = {...formValues, poNumber: '', soNumber: ''};
        saveAndCloneBtnClicked && setFormValues(resource, updatedValues);      
        setTimeout(() => resetOrderAdded(resource), 3000);
    }
  }, [formValues, orderAdded, resetError, resetOrderAdded, saveAndCloneBtnClicked, saveAndNewBtnClicked, saveBtnClicked, setFormValues]);

  useEffect(() => {
    customerOrderInProgress('customerOrder', 'area');
  }, []);

  let customerOrderList = [];

  if (sortValue) {
    customerOrderList = state[resource]?.sortedCustomerOrderInProgress;
  } else {
    customerOrderList = state[resource]?.customerOrderInProgress;
  };

  const header = useMemo(
    () => (
      <div
        ref={listHeaderReference}
        style={{
          width: '100%',
          overflow: 'hidden',
          borderRadius: '12px 12px 0 0',
          borderLeft: '1px solid #29CABA'
        }}>
        <HeaderRow resource={resource} />
      </div>
    ),
    [resource],
  );

  const getCustomerOrderListItemSize = useCallback(
    index => (customerOrderList?.[index] ? customerOrderList[index].rowState.height : defRowHeight),
    [customerOrderList],
  );

  const RenderCustomerOrderRow = useCallback(
    ({ data, index, style }) =>
      index !== undefined && data[index] ? (
        <Row
          listRef={customerOrderRef}
          data={data[index]}
          itemSize={getCustomerOrderListItemSize(index)}
          style={style}
          resource={resource}
          startOnScroll={startOnScroll}
          index={index}
        />
      ) : null,
    [getCustomerOrderListItemSize, resource],
  );

  const allCustomerOrderList = useMemo(
    () =>
      customerOrderList && (
        <AutoSizer disableWidth>
          {({ height }) => (
            <>
              <VariableSizeList
                ref={customerOrderRef}
                outerRef={el => {
                  listReference.current = el;
                }}
                style={{
                        overflowX: 'hidden',
                        overflowY: 'auto',
                        borderBottom: '1px solid #dedede',
                        borderLeft: '1px solid #dedede',
                        borderRight: '1px solid #dedede',
                        borderRadius: '0px 0px 12px 12px',
                    }}      
                itemData={customerOrderList}
                itemCount={customerOrderList.length}
                height={customerOrderList && (customerOrderList.length * 29) > height ? height : customerOrderList.length * 29}
                itemSize={getCustomerOrderListItemSize}>
                {RenderCustomerOrderRow}
              </VariableSizeList>
            </>
          )}
        </AutoSizer>
      ),
    [customerOrderList, getCustomerOrderListItemSize, RenderCustomerOrderRow],
  );

  const handleSelectChange = e => {
    sortBy('customerOrder', e.key);
    setSortValue(e.key);
  };

  const menu = (
    <Menu className='customer-order__menu' onClick={handleSelectChange}>
      <Menu.Item key="byStatuses">By statuses</Menu.Item>
      <Menu.Item key="byDate">By date</Menu.Item>
      <Menu.Item key="byDirection">By direction</Menu.Item>
    </Menu>
  );

  const content = (
    <>
      <div className='customer-order__upper-header'>
        <div className='customer-order__upper-header__left'>
          <div className='customer-order__upper-header__sort'>
            <div className='sort_icon'></div>
            <Dropdown className='customer-order__dropdown' overlay={menu} placement="bottomLeft">
                <span onClick={(e) => e.preventDefault()}>
                  <Space>Sort</Space>
                </span>
            </Dropdown>
          </div>
          <separator className={'customerOrder__separator'} vertical=''></separator>
          <div className='customer-order__upper-header__orders-count'>
            In Transit Orders: <span>{total}</span>
          </div>
        </div>
        <div>
          <Link to="/pastOrder" className='customer-order__upper-header_btn'>PAST ORDERS</Link>
          <button 
            onClick={() => setIsOpen(true)} 
            className='customer-order__upper-header_add_btn'>ADD ORDER
          </button>
        </div>
      </div>
      <div className='headerContainer'>{header}</div>
      <div style={{ flexGrow: 1, width: 'calc(100%)' }}>{allCustomerOrderList}</div>
      <div
        style={{
          width: 'calc(100% + 8px)',
          overflowX: 'scroll',
          flexShrink: 0,
          marginBottom: '3px',
          position: 'absolute',
          bottom: '0px',
          height: '7px',
        }}
        ref={mainScroll}
        onScroll={e => onScrollX(e)}>
        <div
          className='table-body-cell__customer-dashboard'
          style={{
            maxWidth: `${width}px`,
            width: `${width}px`,
            minWidth: `${width}px`,
          }}
        />
      </div>
      {modalIsOpen && 
        <CustomModal
            modalIsOpen={modalIsOpen}
            setIsOpen={setIsOpen}
            setSaveAndNewBtnClicked={setSaveAndNewBtnClicked}
            saveAndNewBtnClicked={saveAndNewBtnClicked}
            saveAndCloneBtnClicked={saveAndCloneBtnClicked}
            setSaveAndCloneBtnClicked={setSaveAndCloneBtnClicked}
            setSaveBtnClicked={setSaveBtnClicked}
        />
      }
      {(errorModalText || actionMessage) &&
        <ErrorModal
            errorModalText={errorModalText}
            setErrorModalText={setErrorModalText}
            message={actionMessage}
            resetError={resetError}
            resource={resource}
            orderAdded={orderAdded}
            hasError={hasError}
            setIsOpen={setIsOpen}
        />
      }
      
    </>
  );

  return <>{loading || addCustomerOrderloading ? <Loading /> : content}</>;
};

const mapStateToProps = state => ({
  state: state,
  customerOrder: state.customerOrderInProgress,
  loading: state.customerOrderInProgress.loading,
  total: state.customerOrderInProgress.total,
  totalUpcomingOrders: state.customerOrderNewOrders.totalOrders,
  addCustomerOrderloading: state.customerOrderNewOrders.addCustomerOrderloading,
  hasError: state.customerOrderNewOrders.hasError,
  actionMessage: state.customerOrderNewOrders.message,
  orderAdded: state.customerOrderNewOrders.orderAdded,
  formValues: state.customerOrderNewOrders.formValues,
});

const mapDispatchToProps = dispatch => ({
  customerOrderInProgress: (resource, endPoint) => dispatch(customerOrderInProgress(resource, endPoint)),
  sortBy: (resource, sortValue) => dispatch(sortBy(resource, sortValue)),
  resetError: resource => dispatch(resetError(resource)),
  resetOrderAdded: resource => dispatch(resetOrderAdded(resource)),  
  setFormValues: (resource, formValues) => dispatch(setFormValues(resource, formValues)),  
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerOrderInProgressSpreadsheet);
