import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Tooltip } from 'antd';
import { enabled } from 'store/dist/store.legacy';
import { Defines } from '../../../../project/Defines';
import { getRevisionOrdersAdmin, acceptRevision, setItemDisplayState } from '../../../../store/actions/revisionOrderAdmin/revisionOrderAdmin';
import RejectModal from './rejectModal';

const Cell = ({ state, cellData, itemSize, rowState, resource, startOnScroll, setItemDisplayState, acceptRevision }) => {
  const customerOrderAdmin = state[resource];
  const redonly = 1;
  const editable = 2;
  const header = customerOrderAdmin.headers.find(h => h.key === cellData.key);
  // const headerIndex = customerOrderAdmin.headers.findIndex(h => h.key === cellData.key);
  const [rejectModalIsOpen, setRejectModalIsOpen] = useState(false);

  const width = header.width;
  //   const offsetWidth = customerOrderAdmin.headers.reduce((r, h, index) => {
  //     if (h.visible && index < (headerIndex - 3)) {
  //         r += h.width;
  //     }
  //     return r;
  // }, 0);

  const lastSelectId = customerOrderAdmin.lastSelected?.id;
  const lastSelectHeaderKey = customerOrderAdmin.lastSelected?.headerKey;
  const lastSelectenable = customerOrderAdmin.lastSelected?.enable;
  const rowStateId = rowState.loadId;
  const headerKey = header.key;
  const [displayState, setDisplayState] = useState(redonly);
  const [tempData, setTempData] = useState(null);

  useEffect(() => {
    if (lastSelectId === rowStateId && lastSelectHeaderKey === headerKey && lastSelectenable) {
      setDisplayState(enabled);
    }
  }, [lastSelectId, lastSelectHeaderKey, lastSelectenable, rowStateId, headerKey]);

  const cellStyle = cellData.cellStyle;
  let data = cellData.valueText && cellData.fieldType === 'text' ? cellData.valueText : cellData.value;
  useEffect(() => {
    setTempData(data);
  }, [data, cellData.priorityValue, cellData.delayedValue, rowState.loadStatus]);

  const inputReference = useRef(null);

  useEffect(() => {
    if (inputReference && inputReference.current) {
      inputReference.current.focus();
    }
  }, [displayState]);

  const handleAccept = () => {
    acceptRevision('CustomerOrderRevisionAdmin', 'acceptrevision', { ids: [rowState.orderData.id] });
  };

  const handleReject = () => {
    setRejectModalIsOpen(true);
  };

  const getData = () => {
    if (tempData !== '' && tempData !== null) {
      switch (cellData.fieldType) {
        case 'dateTime':
          if (cellData.key === 'dueDate') {
            const indicator = rowState?.orderData?.orderIndicator;
            if (rowState?.orderData?.isHardDate) {
              return (
                <Tooltip title='Hard Date'>
                  <span style={{ color: 'rgb(96, 0, 128)' }}>{tempData ? moment(tempData).format(Defines.Format.PrintDateLong) : ''}</span>
                </Tooltip>
              )
            } else if (indicator === 2) {
              return (
                <Tooltip title='Data Change'>
                  <span style={{ color: '#EF2112' }}>{tempData ? moment(tempData).format(Defines.Format.PrintDateLong) : ''}</span>
                </Tooltip>
              )
            } else {
              return (
                <Tooltip title='Standard'>
                  <span style={{ color: '#0000FF' }}>{tempData ? moment(tempData).format(Defines.Format.PrintDateLong) : ''}</span>
                </Tooltip>
              )
            }
          } else {
            return <>{tempData ? moment(tempData).format(Defines.Format.PrintDateLong) : ''}</>;
          }
        case 'text':
          if (cellData.key === 'so' && tempData) {
            const withTL = tempData?.slice(0, 2) === 'TL';
            if (withTL) {
              return <div style={{color: 'red'}}>{tempData}</div> 
            }
          } else {
            return tempData;
          }
          break
        case 'revisionText': {
          if (tempData[0] === '' || tempData[1] === '') {
            return (
              <>
                <span style={{ color: '#080E0E' }}>{tempData[0]}</span>
                <span style={{ color: '#FF0000' }}>{tempData[1]}</span>
              </>
            );
          } else if (tempData[0] === tempData[1]) {
            return <span style={{ color: '#080E0E' }}>{tempData[0]}</span>;
          } else {
            return (
              <>
                <span style={{ color: '#080E0E' }}>{tempData[0] || 0}</span>/<span style={{ color: '#FF0000' }}>{tempData[1] || 0}</span>
              </>
            );
          }
        }
        case 'actions':
          return (
            <div className='edit_delete_icons'>
              <div onClick={handleAccept}>
                <button className='revision__accept-button'>ACCEPT</button>
              </div>
              <div onClick={handleReject}>
                <button className='revision__reject-button'>REJECT</button>
              </div>
            </div>
          );
        case 'link':
          return (
            <Link style={{ textDecoration: 'underline' }}
              to={
                rowState.orderData.direct
                  ? `/loadplanning/outboundloadbuld?id=${rowState.orderData.revisionOrderid}`
                  : `/loadplanning/inboundloadbuld?id=${rowState.orderData.revisionOrderid}`
              }>
              {rowState.orderData.revisionOrderid}
            </Link>
          );
        default:
          return tempData;
      }
    }
    return tempData;
  };

  const dataDisplay = () => getData();

  const cellDefaultStyle = {
    maxWidth: `${width}px`,
    width: `${width}px`,
    minWidth: `${width}px`,
    height: `${itemSize}px`,
    color: cellData.color,
    padding: '4px 2px',
  };

  const onSetFocus = () => {
    // startOnScroll && startOnScroll(offsetWidth);
    if (cellData.editable) {
      setDisplayState(editable);
    }
    // setItemDisplayState(resource, rowState, header, cellData.editable);
  };

  const editableFunction = cellData => (cellData.editable ? setDisplayState(editable) : null);

  return (
    <>
      <div
        onFocus={() => onSetFocus()}
        onClick={() => {
          editableFunction(cellData);
        }}
        className='table-body-cell table-borders last-order-row__revision'
        style={{ ...cellDefaultStyle, ...cellStyle }}
        tabIndex={displayState === redonly ? 0 : -1}>
        {dataDisplay()}
      </div>
      {rejectModalIsOpen && (
        <RejectModal rejectModalIsOpen={rejectModalIsOpen} setRejectModalIsOpen={setRejectModalIsOpen} editMode id={rowState.orderData.id} />
      )}
    </>
  );
};

const mapStateToProps = state => ({
  state: state,
  total: state.revisionOrderAdmin.total,
});

const mapDispatchToProps = dispatch => ({
  setItemDisplayState: (resource, rowState, header, enable) => dispatch(setItemDisplayState(resource, rowState, header, enable)),
  getRevisionOrdersAdmin: resource => dispatch(getRevisionOrdersAdmin(resource)),
  acceptRevision: (resource, endPoint, data) => dispatch(acceptRevision(resource, endPoint, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Cell);
