export const customStyles = {
  control: (base, state) => ({
    ...base,
    boxShadow: 'none',
    width: '100%',
    cursor: 'notAllowed',
    border: '1px solid lightgray',
    borderRadius: '4px',
    background: 'white',
    minHeight: '34px',
  }),
  menu: (base, state) => ({
    ...base,
    marginTop: '4px',
  }),
  menuList: () => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    borderRadius: 'none!important',
    overflowY: 'scroll',
    // height: '200px',
  }),
  valueContainer: (base, state) => ({
    display: 'flex',
    alignItems: 'self-end',
    paddingLeft: '5px',
    maxWidth: '224px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }),
  container: (base, state) => ({
    ...base,
    pointerEvents: 'auto',
  }),
  singleValue: (base, state) => ({
    maxWidth: '224px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }),
  input: (base, state) => ({
    padding: 0,
    margin: 0,
  }),
  options: (base, state) => ({
    padding: '3px 12px',
    cursor: 'pointer',
    borderRadius: 'unset',
  }),
  indicatorsContainer: () => ({
    marginRight: '14px',
    color: 'gray',
  }),
  indicatorContainer: () => ({
    padding: '0 4px 8px 0',
  }),
};

export const mapContainerStyle = {
  width: '100%',
  height: '84vh',
  margin: '24px',
}