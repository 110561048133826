import ResizableTitle from '../components/list/resizableTitle';

export const DefaultView = {
    AutoHeight: null,
    Small: null,
    AllowNewButton: true,
    AllowCreateGroupButton: false,
    HideSettings: false
};

export const DefaultTable = {
    Style: {
        components: {
            header: {
                cell: ResizableTitle,
            },
        },
        tableLayout: null,
        showSorterTooltip: false,
        rowSelection: null,
        summary: null,
        rowClassName: null,
        // scroll: { y: 100 },
        size: 'small',
        bordered: false,
        title: undefined,
        showHeader: true ,
        footer: false,
        hasData: true,
        top: 'none',
        bottom: 'bottomRight'
    }
};



export const DefaultSummaryTable = {
    Style: {
        components: {
            header: {
                cell: ResizableTitle,
            },
        },
        tableLayout: true,
        showSorterTooltip: false,
        rowSelection: null,
        rowClassName: null,
        size: 'small',
        bordered: true,
        footer: false,
        hasData: true,
        top: 'none',
        bottom: 'bottomRight'
    }
};

export const InlineTable = {
    Style: {
        components: null,
        tableLayout: null,
        showSorterTooltip: false,
        rowSelection: null,
        summary: null,
        rowClassName: null,
        scroll: { y: 100 },
        size: 'small',
        bordered: false,
        title: undefined,
        showHeader: true,
        footer: false,
        hasData: true,
        top: 'none',
        bottom: 'bottomRight'
    }
};

export const Defaults = {
    View: DefaultView,
    Table: DefaultTable,
    SummaryTable: DefaultSummaryTable
};

export const DefaultPagination = {
    Current: 1,
    PageSize: 20,
    ShowSizeChanger: true,
    PageSizeOptions: ['5', '10', '20', '30', '40']
};

export const ColumnType = {
    Text: 'Text',
    MultilineText: 'MultilineText',
    Date: 'Date',
    Time: 'Time',
    DateTime: 'Datetime',
    Number: 'Number',
    Currency: 'Currency',
    Phone: 'Phone',
    Bool: 'Bool',
    Radio: 'Radio',
    Label: 'Label',
    SimpleIndicator: 'SimpleIndicator',
    Indicator: 'Indicator',
    StatusIndicator: 'StatusIndicator',
    CommentIndicator: 'CommentIndicator',
    CancelIndicator: 'CancelIndicator',
    Badge: 'Badge',
    Empty: 'Empty',
    Button: 'Button',
    FileSize: 'FileSize',
    File: 'File'
};

export const FilterType = {
    Text: 'Text',
    Select: 'Select',
    MultiSelect: 'MultiSelect',
    Date: 'Date',
    Checkbox: 'Checkbox',
    Radio: 'Radio',
    Draggable: 'Draggable'
};

export const ValueListUrls = {
    Customer: '/api/customer/customerkeyvaluewithdefloclist',
    Location: '/api/location/locationlist/',
    LocationDoor: '/api/location/locationdoorlist/',
    LocationDoorListByParentId: '/api/location/locationdoorlistbyparentid/',
    Temperature: '/api/OrderTemperatures/availableordertemperaturelist',
    AreaGroup: '/api/AreaGroup/',
};