import { AREA_GROUP_DETAILS_BUILD as actionTypes } from '../../actions/areaDetails/areaGroupDetailsActionTypes';
import * as areaGroup from './areaGroupDetailsReducer';

const defaultState = {
  headers: [
    {
      key: 'name',
      title: 'Name',
      width: 200,
      sortable: true,
    },
  ],
  loading: false,
  hasError: false,
  messageText: '',
  areaGroupList: null,
  areaGroupDetails: null,
  sortType: 'asc',
  newAreaGroupAdded: false,
  areaGroupUpdated: false,
  statesList: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.ERROR:
      return areaGroup.setError(state, action.data);
    case actionTypes.RESET_ERROR:
      return areaGroup.resetError(state);
    case actionTypes.RELOAD_ON_ERROR:
      return areaGroup.getAreaGroupList(state, action.data);
    case actionTypes.GET_AREA_GROUP_DETAILS_LIST_LOADING:
      return areaGroup.setInLoading(state);
    case actionTypes.GET_AREA_GROUP_DETAILS_LIST:
      return areaGroup.getAreaGroupList(state, action.data);
    case actionTypes.GET_AREA_GROUP_DETAILS_DETAILS_LOADING:
      return areaGroup.setInLoading(state);
    case actionTypes.GET_AREA_GROUP_DETAILS_DETAILS:
      return areaGroup.getAreaGroupDetails(state, action.data);
    case actionTypes.GET_STATES_LIST:
      return areaGroup.getStatesList(state, action.data);
    case actionTypes.ADD_AREA_GROUP_DETAILS_LOADING:
      return areaGroup.setInLoading(state, action.data);
    case actionTypes.ADD_AREA_GROUP_DETAILS:
      return areaGroup.addAreaGroupDetails(state, action.data);
    case actionTypes.UPDATE_AREA_GROUP_DETAILS:
      return areaGroup.updateAreaGroupDetails(state, action.data);
    case actionTypes.GET_LOCATIONS_LIST:
      return areaGroup.getStatesList(state, action.data);
    case actionTypes.GET_LOCATIONS_LIST_BY_STATE_IDS:
      return areaGroup.getLocationsByStateIds(state, action.data);
    case actionTypes.EMPTY_ALL_LOCATIONS:
      return areaGroup.emptyAllLocations(state, action.data);
    case actionTypes.GET_AREA_GROUP_DETAILS_BY_ID:
      return areaGroup.getAreaGroupDetailsById(state, action.data);
    case actionTypes.GET_AREA_GROUP_ALL_LOCATIONS_LIST:
      return areaGroup.getAreaGroupAllLocationsList(state, action.data);
    default:
      return state;
  }
};
