import { CUSTOMER_ORDERS_BUILD as actionTypes } from '../../actions/customerOrderNewOrders/customerOrderActionTypes';
import * as customerOrder from './customerOrderNewOrdersReducers';

const defaultState = {
  headers: [
    {
      key: 'readyDate',
      title: 'READY DATE',
      width: 80,
    },
    {
      key: 'dueDate',
      title: 'DUE DATE',
      width: 80,
    },
    {
      key: 'pickLocation',
      title: 'PICK LOCATION',
      width: 100,
    },
    {
      key: 'dropLocation',
      title: 'DELIVERY WH',
      width: 100,
    },
    {
      key: 'poNumber',
      title: 'PO#',
      width: 60,
    },
    {
      key: 'soNumber',
      title: 'SO#',
      width: 60,
    },
    {
      key: 'palletCount',
      title: 'PAL',
      width: 40,
    },
    {
      key: 'spa',
      title: 'SPA',
      width: 40,
    },
    {
      key: 'lbs',
      title: 'LBS',
      width: 50,
    },
    {
      key: 'temperature',
      title: 'TEMP',
      width: 60,
    },
    // {
    //   key: 'freight',
    //   title: 'FREIGHT VALUE',
    //   width: 60,
    // },
    {
      key: 'orderCycle',
      title: 'ORDER CYCLE',
      width: 70,
    },
    {
      key: 'actions',
      title: '',
      width: 90,
    },
  ],
  loading: false,
  addCustomerOrderloading: false,
  hasError: false,
  message: '',
  total: 0,
  totalOrders: 0,
  existing: 0,
  customerOrder: null,
  orderAdded: false,
  orderUpdated: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.CUSTOMER_ORDERS_LOADING:
      return customerOrder.setInLoading(state);
    case actionTypes.SET_HEADER_WIDTH_NEW_ORDERS:
      return customerOrder.setHeaderWidth(state, action.data.ind, action.data.width);
    case actionTypes.ERROR:
      return customerOrder.setError(state, action.data);
    case actionTypes.RESET_ERROR:
      return customerOrder.resetError(state);
    case actionTypes.RELOAD_ON_ERROR:
      return customerOrder.loadContent(state, action.data, true, 'Something go wrong, data reloaded!');
    case actionTypes.GET_CUSTOMER_ORDERS_NEW_ORDERS:
      return customerOrder.customerOrder(state, action.data);
    case actionTypes.SORT_ORDERS_NEW_ORDERS:
      return customerOrder.sortBy(state, action.data);
    case actionTypes.DELETE_CUSTOMER_ORDER:
      return customerOrder.deleteCustomerOrder(state, action.data);
    case actionTypes.ADD_CUSTOMER_ORDER:
      return customerOrder.addCustomerOrder(state, action.data);
    case actionTypes.ADD_CUSTOMER_ORDER_LOADING:
      return customerOrder.setAddCustomerOrderLoading(state, action.data);
    case actionTypes.SET_FORM_VALUES:
      return customerOrder.setFormValues(state, action.data);
    case actionTypes.RESET_ORDER_ADDED:
      return customerOrder.resetOrderAdded(state, action.data);
    case actionTypes.EDIT_CUSTOMER_ORDER:
      return customerOrder.editCustomerOrder(state, action.data);
    case actionTypes.GET_TEMPERATURES_LIST:
      return customerOrder.getTemperaturesList(state, action.data);
    default:
      return state;
  }
};
