import { orderBy } from "lodash";
const itemDefaultSize = 28;

export const createWebOrder = (oData, headers) => {
  const data = { ...oData };
  const orderWebData = {
    row: [],
    rowState: {
      loadId: `load_${data.loadId}`,
      orderId: data.id,
      type: data.loadType,
      orderData: data,
      height: itemDefaultSize,
    },
  };

  for (let headerIndex = 0; headerIndex < headers.length; headerIndex++) {
    const headerItem = headers[headerIndex];
    let value = null;
    let fieldType = 'text';
    switch (headerItem.key) {
      case 'readyDate':
        fieldType = 'dateTime';
        value = data.readyDate;
        break;
      case 'pueDate':
        fieldType = 'dateTime';
        value = data.puDate;
        break;
      case 'dueDate':
        fieldType = 'dateTime';
        value = data.dueDate;
        break;
      case 'pickLocation':
        value = data.pickLocation;
        fieldType = 'text';
        break;
      case 'deliverWH':
        value = data.direct ? data.drop1WH : data.drop2WH;
        fieldType = 'text';
        break;
      case 'deliverCity':
        value = data.direct ? data.drop1City : data.drop2City;
        fieldType = 'text';
        break;
      case 'deliverState':
        value = data.direct ? data.drop1State : data.drop2State;
        fieldType = 'text';
        break;
      case 'temperature':
        value = data.temperatureName;
        fieldType = 'text';
        break;
      // case 'freight':
      //   value = data.freight;
      //   fieldType = 'text';
      //   break;        
      case 'poNumber':
        value = data.poNumber;
        fieldType = 'text';
        break;
      case 'soNumber':
        value = data.soNumber;
        break;
      case 'palletCount':
        value = data.revisionPalletCount && (data.revisionPalletCount !== data.palletCount) ? [data.palletCount, data.revisionPalletCount] : data.palletCount;
        fieldType = data.revisionPalletCount && (data.revisionPalletCount !== data.palletCount) ? 'revisionText' : 'text';
        break;
      case 'spa':
        value = data.revisionSpace > -1 && (data.revisionSpace !== data.space) ? [data.space, data.revisionSpace] : data.space;
        fieldType = data.revisionSpace > -1 && (data.revisionSpace !== data.space) ? 'revisionText' : 'text';
        break;
      case 'lbs':
        value = (data.revisionWeight || data.revisionWeight === 0) && (data.revisionWeight !== data.weight) ? [data.weight, data.revisionWeight] : data.weight;
        fieldType = (data.revisionWeight || data.revisionWeight === 0) && (data.revisionWeight !== data.weight)  ? 'revisionText' : 'text';
        break;
      case 'price':
        value = data.price;
        break;
      case 'notes':
        value = data.revisionNotes && (data.revisionNotes !== data.customerOrderNotes) ? [data.customerOrderNotes, data.revisionNotes] : data.customerOrderNotes;
        fieldType = data.revisionNotes && (data.revisionNotes !== data.customerOrderNotes) ? 'revisionText' : 'text';
        break;
      case 'orderCycle':
        value = data.orderStatus;
        break;
      case 'podStatus':
        value = data.outNotes;
        break;
      case 'actions':
        fieldType = 'actions';
        value = 'button';
        break;
      default:
        break;
    }
    orderWebData.row.push({
      key: headerItem.key,
      value,
      fieldType,
    });
  }
  return orderWebData;
};

const createCustomerOrdersList = (state, customerOrders) => customerOrders.map(data => createWebOrder(data, state.headers));

export const customerOrder = (state, responce, hasError = false, message = '') => {
  let { instantOrders, revisionOrders } = responce.data;

  for (let i = 0; i < instantOrders.data.length; i++) {
    for (let j = 0; j < revisionOrders.data.length; j++) {
        if (instantOrders.data[i].id === revisionOrders.data[j].revisionOrderid) {
          instantOrders.data[i].revisionPalletCount = revisionOrders.data[j].revisionPalletCount
          instantOrders.data[i].revisionSpace = revisionOrders.data[j].revisionSpace
          instantOrders.data[i].revisionWeight = revisionOrders.data[j].revisionWeight
          instantOrders.data[i].revisionNotes = revisionOrders.data[j].revisionNotes
        };
    };
  };

  const customerOrderInProgress = createCustomerOrdersList(state, instantOrders.data);

  return {
    ...state,
    ...{
      loading: false,
      hasError,
      message,
      customerOrderInProgress: customerOrderInProgress,
      total: instantOrders.total,
    },
  };
};

export const revisionCustomerOrder = (state, responce) => {
  return {
    ...state,
    ...{      
      orderUpdated: !responce.data.hasError,
    },
  };
};

const sortByValue = (customerOrderInProgress, value) => {
  let sortedList = [];
  if (value === 'byStatuses') {
    sortedList = orderBy(customerOrderInProgress, [c => c.row[17].value], ["asc"]);
  };
  if (value === 'byDate') {
    sortedList = orderBy(customerOrderInProgress, [c => c.row[0].value], ["asc"]);
  };
  if (value === 'byDirection') {
    sortedList = orderBy(customerOrderInProgress, [c => c.row[5].value], ["asc"]);
  };
  return sortedList;
};

export const sortBy = (state, sortValue) => ({
  ...state,
  ...{
    sortedCustomerOrderInProgress: sortByValue(state.customerOrderInProgress, sortValue),
  },
});

export const setDisplayState = (state, rowState, header, enable) => ({
  ...state,
  ...{
    lastSelected: {
      id: rowState.id,
      headerKey: header.key,
      enable: enable,
    },
  },
});

export const setHeaderWidth = (state, ind, width) => {
  const header = state.headers[ind];
  header.width = width;
  return {
    ...state,
    ...{
      headers: [...state.headers.slice(0, ind), ...[header], ...state.headers.slice(ind + 1)],
    },
  };
};

export const loadContent = (state, response, hasError = false, message = '') => {
  return customerOrder(state, response, hasError, message);
};

export const resetError = state => ({
  ...state,
  ...{
    hasError: false,
    message: '',
  },
});

export const setInLoading = state => ({
  ...state,
  ...{
    loading: true,
  },
});
