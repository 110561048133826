import React, { lazy, useState, useEffect } from 'react';
import { Switch, Redirect } from 'react-router-dom';

import NotFound from '../../pages/notFound';
import { AppPages } from '../../../project/Pages';
import Grid from '../../pages/grid';
import Details from '../../pages/details';
import Loading from '../screen/loading';
import Development from '../../samples/development';
//import Load from '../project/load';
import Load from '../project/loadArea/inboundLoad';
import ExperimentalLoad from '../project/loadArea/experimentalLoad';
//import InboundBooking from '../project/inboundBooking';
import InLoadInAppts from '../project/loadArea/inboundLoad/inLoadInAppts';
//import InboundBooking from '../project/loadArea/inboundLoad/booking';
//import InboundAppointment from '../project/loadArea/inboundLoad/appointment';
//import InboundInTransit from '../project/inboundInTransit';
import InboundInTransit from '../project/loadArea/inboundLoad/inTransit';
import Receiving from '../project/receiving';
import Outbound from '../project/loadArea/outboundLoad';
//import Outbound from '../project/outbound';
//import OutboundBooking from '../project/outboundBooking';
//import OutboundBooking from '../project/loadArea/outboundLoad/booking';
import OutLoadOutAppts from '../project/loadArea/outboundLoad/outLoadOutAppts';
//import OutboundAppointment from '../project/loadArea/outboundLoad/appointment';
//import OutboundInTransit from '../project/outboundInTransit';
import OutboundInTransit from '../project/loadArea/outboundLoad/inTransit';
import CustomerDashboard from '../project/customerDashboard';
import CustomerOrder from '../project/loadArea/spreadsheet/customerOrderBuild';
import CustomerOrderLastAndRejected from '../project/loadArea/spreadsheet/customerOrderLastAndRejectedBuild';
import CustomerOrderAdmin from '../project/loadArea/spreadsheet/customerOrderAdminBuild';
import RevisionOrderAdmin from '../project/loadArea/spreadsheet/revisionOrderAdminBuild';
import AdminCallCheck from '../project/loadArea/spreadsheet/adminCallCheckBuild';
import FactoringCompany from '../project/loadArea/spreadsheet/factoringCompanyBuild';
import FactoringCompanyDetails from '../project/FactoringCompany/FactoringCompanyDetails';
import AreaGroupDetailsInfoPage from '../project/AreaGroupDetails/AreaGroupDetailsInfoPage';
import DeliveredLoads from '../project/loadArea/spreadsheet/deliveredLoadsBuild';
import AdminOrder from '../project/loadArea/spreadsheet/adminOrderBuild';

import InboundLoadBuld from '../project/loadArea/spreadsheet/inboundLoadBuild';
import OutboundLoadBuld from '../project/loadArea/spreadsheet/outboundLoadBuild';
import ManifestList from '../project/loadArea/manifest';
import TrafficControl from '../project/loadArea/spreadsheet/trafficControlLoadBuild';
import TrafficControlUpcoming from '../project/loadArea/spreadsheet/trafficControlUpcomingLoadBuild';

import CacheBusterRoute from '../../../CacheBusterRoute';
import APDeliveredTrucksBuild from '../project/loadArea/spreadsheet/APDeliveredTrucksBuild';
import AccountReceivableBuild from '../project/loadArea/spreadsheet/accountReceivableBuild';
import CustomersBuild from '../project/loadArea/spreadsheet/customersBuild';

const importView = component =>
  lazy(() => {
    return import(`codeless/screens/${component}/config`)
      .then(res => res)
      .catch(() => '');
  });

const Router = props => {
  const [state, setState] = useState([]);

  useEffect(() => {
    const newRoutsList = [
      ...props.routeList(),
      {
        path: '/LOADPLANNING/locationdetails/:id/:childId',
        screen: 'Location',
        has_grid_list: false,
      },
      {
        path: '/OTR/trafficControlUpcoming',
        screen: 'trafficControlUpcoming',
        has_grid_list: true,
      },
      {
        path: '/booking/trafficControlUpcomingBooking',
        screen: 'trafficControlUpcomingBooking',
        has_grid_list: true,
      },
      {
        path: '/tstcold/trafficControlUpcomingTstCold',
        screen: 'trafficControlUpcomingTstCold',
        has_grid_list: true,
      },
      {
        path: '/loadplanning/customerPrice',
        screen: 'customerPrice',
        has_grid_list: true,
      },
      {
        path: '/otr/factoringDetails',
        screen: 'factoringDetails',
        has_grid_list: true,
      },
    ];
    const checkPath = () => {
      const promiseArr = newRoutsList.map(async route => {
        if ('Development' === route.screen) {
          return <CacheBusterRoute key={route.path} rKey={route.path} path={route.path} component={Development} />;
        }
        if (route.screen === 'inboundLoadBuld' || route.screen === 'inboundLoadBuld_booking') {
          return <CacheBusterRoute key={route.path} rKey={route.path} path={route.path} component={() => <InboundLoadBuld />} />;
        }
        if (route.screen.toLowerCase() === 'outboundloadbuld' || route.screen.toLowerCase() === 'outboundloadbuld_booking') {
          return <CacheBusterRoute key={route.path} rKey={route.path} path={route.path} component={() => <OutboundLoadBuld />} />;
        }
        if (route.screen === 'Traffic' || route.screen === 'Traffic_TSTcold' || route.screen === 'Traffic_Booking') {
          let upcomingUrl = '/otr/trafficcontrolupcoming';
          if (route.screen === 'Traffic_Booking') {
            upcomingUrl = '/booking/trafficControlUpcomingBooking';
          } else if (route.screen === 'Traffic_TSTcold') {
            upcomingUrl = '/tstcold/trafficControlUpcomingTstCold';
          }
          return (
            <CacheBusterRoute key={route.path} rKey={route.path} path={route.path} component={() => <TrafficControl upcomingUrl={upcomingUrl} />} />
          );
        }
        if (
          route.screen === 'trafficControlUpcoming' ||
          route.screen === 'trafficControlUpcomingBooking' ||
          route.screen === 'trafficControlUpcomingTstCold'
        ) {
          let trafficUrl = '/otr/traffic';
          if (route.screen === 'trafficControlUpcomingBooking') {
            trafficUrl = '/booking/traffic_booking';
          } else if (route.screen === 'trafficControlUpcomingTstCold') {
            trafficUrl = '/tstcold/traffic_tstcold';
          }
          return (
            <CacheBusterRoute
              key={route.path}
              rKey={route.path}
              path={route.path}
              component={() => <TrafficControlUpcoming trafficUrl={trafficUrl} />}
            />
          );
        }
        if (route.screen === 'ExperimentalLoad') {
          return <CacheBusterRoute key={route.path} rKey={route.path} path={route.path} component={() => <ExperimentalLoad />} />;
        }
        if (route.screen === 'Load') {
          return <CacheBusterRoute key={route.path} rKey={route.path} path={route.path} component={() => <Load />} />;
        }
        if (route.screen === 'InLoadInAppts') {
          return <CacheBusterRoute key={route.path} rKey={route.path} path={route.path} component={() => <InLoadInAppts />} />;
        }
        if (route.screen === 'InboundInTransit') {
          return <CacheBusterRoute key={route.path} rKey={route.path} path={route.path} component={() => <InboundInTransit />} />;
        }
        if (route.screen === 'OutboundLoad') {
          return <CacheBusterRoute key={route.path} rKey={route.path} path={route.path} component={() => <Outbound />} />;
        }
        if (route.screen === 'OutLoadOutAppts') {
          return <CacheBusterRoute key={route.path} rKey={route.path} path={route.path} component={() => <OutLoadOutAppts />} />;
        }
        if (route.screen === 'manifest') {
          return <CacheBusterRoute key={route.path} rKey={route.path} path={route.path} component={() => <ManifestList />} />;
        }
        if (route.screen === 'OutboundInTransit') {
          return <CacheBusterRoute key={route.path} rKey={route.path} path={route.path} component={() => <OutboundInTransit />} />;
        }
        let view = importView(route.screen);
        let promise = view._ctor();
        let res = await promise;
        if (route.screen === 'ReceivingLoad') {
          return <CacheBusterRoute key={route.path} rKey={route.path} path={route.path} component={() => <Receiving {...res.default} />} />;
        }
        if (route.screen === 'CustomerDashboard') {
          return (
            <CacheBusterRoute
              key={route.path}
              rKey={route.path}
              path={route.path}
              component={() => (route.has_grid_list ? <CustomerDashboard {...res.default} /> : Details(res.default))}
            />
          );
        }
        if (route.screen === 'CustomerOrder') {
          return (
            <CacheBusterRoute
              key={route.path}
              rKey={route.path}
              path={route.path}
              component={() => (route.has_grid_list ? <CustomerOrder {...res.default} /> : Details(res.default))}
            />
          );
        }
        if (route.screen === 'pastOrder') {
          return (
            <CacheBusterRoute
              key={route.path}
              rKey={route.path}
              path={route.path}
              component={() => (route.has_grid_list ? <CustomerOrderLastAndRejected {...res.default} /> : Details(res.default))}
            />
          );
        }
        if (route.screen === 'CustomerOrderAdmin') {
          return (
            <CacheBusterRoute
              key={route.path}
              rKey={route.path}
              path={route.path}
              component={() => (route.has_grid_list ? <CustomerOrderAdmin {...res.default} /> : Details(res.default))}
            />
          );
        }
        if (route.screen === 'revision' || route.screen === 'revision_OTR') {
          return (
            <CacheBusterRoute
              key={route.path}
              rKey={route.path}
              path={route.path}
              component={() => (route.has_grid_list ? <RevisionOrderAdmin {...res.default} /> : Details(res.default))}
            />
          );
        }
        if (route.screen === 'DeliverdLoads') {
          return (
            <CacheBusterRoute
              key={route.path}
              rKey={route.path}
              path={route.path}
              component={() => (route.has_grid_list ? <DeliveredLoads {...res.default} /> : Details(res.default))}
            />
          );
        }
        if (route.screen === 'CallCheck') {
          return (
            <CacheBusterRoute
              key={route.path}
              rKey={route.path}
              path={route.path}
              component={() => (route.has_grid_list ? <AdminCallCheck {...res.default} /> : Details(res.default))}
            />
          );
        }
        if (route.screen === 'factoringDetails') {
          return (
            <CacheBusterRoute key={route.path} rKey={route.path} path={route.path} component={() => <FactoringCompanyDetails {...res.default} />} />
          );
        }
        if (route.screen === 'factoringCompany') {
          return <CacheBusterRoute key={route.path} rKey={route.path} path={route.path} component={() => <FactoringCompany {...res.default} />} />;
        }
        if (route.screen === 'areaGroupDetailsPage') {
          return <CacheBusterRoute key={route.path} rKey={route.path} path={route.path} component={() => <AreaGroupDetailsInfoPage {...res.default} />} />;
        }
        if (
          route.screen.toLowerCase() === 'deliveredtrucks' ||
          route.screen.toLowerCase() === 'readytopay' ||
          route.screen.toLowerCase() === 'paid'
        ) {
          return (
            <CacheBusterRoute
              key={route.path}
              rKey={route.path}
              path={route.path}
              component={() => (route.has_grid_list ? <APDeliveredTrucksBuild {...res.default} /> : Details(res.default))}
            />
          );
        }
        if (
          route.screen.toLowerCase() === 'deliverednopod' ||
          route.screen.toLowerCase() === 'readytobill' ||
          route.screen.toLowerCase() === 'alreadybilled' ||
          route.screen.toLowerCase() === 'openorders' ||
          route.screen.toLowerCase() === 'changerequestsar'
        ) {
          return (
            <CacheBusterRoute
              key={route.path}
              rKey={route.path}
              path={route.path}
              component={() => (route.has_grid_list ? <AccountReceivableBuild {...res.default} /> : Details(res.default))}
            />
          );
        }
        if (route.screen === 'Order') {
          return (
            <CacheBusterRoute
              key={route.path}
              rKey={route.path}
              path={route.path}
              component={() => (route.has_grid_list ? <AdminOrder {...res.default} /> : Details(res.default))}
            />
          );
        }
        if (route.screen === 'customerPrice') {
          return (
            <CacheBusterRoute
              key={route.path}
              rKey={route.path}
              path={route.path}
              component={() => (route.has_grid_list ? <CustomersBuild {...res.default} /> : Details(res.default))}
            />
          );
        }
        return res ? (
          <CacheBusterRoute
            exact={true}
            key={route.path}
            rKey={route.path}
            path={route.path}
            component={() => (route.has_grid_list ? Grid : Details)({ ...res.default })}
          />
        ) : (
          <CacheBusterRoute key={route.path} rKey={route.path} path={route.path} component={NotFound} />
        );
      });
      Promise.all(promiseArr).then(setState);
    };
    checkPath();
  }, [props.routeList, props]);

  return (
    <Switch>
      <CacheBusterRoute exact path='/'>
        <Redirect to={'/' + AppPages.DefaultPage} />
      </CacheBusterRoute>
      <CacheBusterRoute exact path='/loadplanning/changerequest'>
        <Redirect to={'/changerequest/customerorderadmin'} />
      </CacheBusterRoute>
      <>{state.length ? state : <Loading />}</>
      <CacheBusterRoute path='*' component={NotFound} />
    </Switch>
  );
};

export default Router;
