export const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '400px',
    minHeight: '200px',
    borderRadius: '8px',
    padding: 0,
  },
};

export const customStyleLoadDetailsPage = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '968px',
    minHeight: '600px',
    borderRadius: '8px',
    padding: '0',
    width: '90%',
    maxWidth: '968px',
    height: '-webkit-fill-available',
    overflowY: 'scroll',
  },
};

export const customStylesLoadDetailsPage = {
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  control: (base, state) => ({
    ...base,
    borderColor: state.isFocused ? 'lightGray' : 'lightGray',
    borderRadius: '8px',
    boxShadow: 'none',
    minHeight: '37px',
    width: '100%',
    cursor: 'notAllowed',
  }),
  menu: (base, state) => ({
    ...base,
    zIndex: '20',
  }),
  menuList: () => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    borderRadius: 'none!important',
  }),
  valueContainer: (base, state) => ({
    display: 'flex',
    alignItems: 'self-end',
    paddingLeft: '5px',
    maxWidth: '224px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }),
  container: (base, state) => ({
    ...base,
    pointerEvents: 'auto',
  }),
  singleValue: (base, state) => ({
    maxWidth: '224px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }),
  options: (base, state) => ({
    padding: '4px 8px',
    cursor: 'pointer',
    borderRadius: 'unset',
  }),
};

export const stylesFilterMain = {
  control: (base, state) => ({
      ...base,
      borderRadius: '8px',
      boxShadow: 'none',
      minHeight: '22px',
      minWidth: '150px',
      paddingLeft: '10px',
      height: '24px',
      alignContent: 'center',
  }),
  menu: (base, state) => ({
      ...base,
      boxShadow: '0',
      top: 20,
  }),
  input: (base, state) => ({
      ...base,
      height: 0,
      position: 'absolute',
      top: '0',
      marginTop: '-2px',
  }),
  valueContainer: (base, state) => ({
      height: '-webkit-fill-available',
  }),
  singleValue: (base, state) => ({
      maxWidth: '170px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
  }),
  menuList: (base, state) => ({
      ...base,
      position: 'fixed',
      maxHeight: "-webkit-fill-available",
      padding: 0,
      "::-webkit-scrollbar": {
          width: "4px",
          height: "0px",
      },
      "::-webkit-scrollbar-track": {
          background: "#f1f1f1"
      },
      "::-webkit-scrollbar-thumb": {
          background: "#888"
      },
      "::-webkit-scrollbar-thumb:hover": {
          background: "#555"
      }
  }),
  option: (defaultStyles, state) => ({
      ...defaultStyles,
      color: state.isSelected ? "#000" : "#000",
      backgroundColor: state.isSelected ? "lightGrey" : "#fff",
      border: '1px solid lightGray'
  }),
  indicatorsContainer: () => ({
      height: 'max-content',
  }),
  indicatorContainer: () => ({
      padding: '0 8px!important',
  }),
};

export const stylesFilter = {
  ...stylesFilterMain,
  control: (base, state) => ({
      ...base,
      borderRadius: '8px',
      boxShadow: 'none',
      minHeight: '22px',
      minWidth: '80px',
      paddingLeft: '10px',
      height: '24px',
      alignContent: 'center',
  }),
};

export const stylesFilterTwoFilters = {
  ...stylesFilterMain,
  control: (base, state) => ({
    ...base,
    borderRadius: '8px',
    boxShadow: 'none',
    minHeight: '22px',
    minWidth: '110px',
    width: '146px',
    paddingLeft: '10px',
    height: '24px',
    alignContent: 'center',
}),
};
