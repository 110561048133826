import React, { useEffect, useRef, useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';
import { Dropdown, Space, message, Menu } from 'antd';
import './index.css';
import HeaderRow from './headerRow';
import Row from './row';
import Loading from '../../screen/loading';
import { customerOrderNewOrders, sortBy, resetError, setFormValues } from '../../../../store/actions/customerOrderNewOrders/customerOrderNewOrders';
import { getContentWidth } from '../../../../store/reducers/customerOrderNewOrders/helper/general';
import { useTidioScript } from "../../../hook/data/useTidioScript";

const CustomerOrderNewOrdersSpreadsheet = props => {
    const {
        customerOrderNewOrders,
        sortBy,
        loading,
        resetError,
        state,
        totalOrders,
        orderUpdated,
        setFormValues,
    } = props;
    const resource = 'customerOrderNewOrders';
    const defRowHeight = 40;
    const scrollSize = 0;
    const customerOrderRef = useRef(null);
    const listReference = useRef(null);
    const listHeaderReference = useRef(null);
    const mainScroll = useRef(null);
    const startOnScroll = width => (mainScroll.current.scrollLeft = width);
    const headers = state[resource].headers;
    const width = getContentWidth(headers) + scrollSize;
    const innerWidth = window.innerWidth;
    const smallScreen = width > innerWidth;
    const [sortValue, setSortValue] = useState('');

    useTidioScript();

    const onScrollX = e => {
        if (listHeaderReference.current && listReference.current && e.target) {
        listHeaderReference.current.scrollLeft = listReference.current.scrollLeft = e.target.scrollLeft;
        }
    };

    useEffect(() => {
        customerOrderNewOrders('customerOrder', 'myorderorderlist');
    }, []);

    useEffect(() => {
        if (orderUpdated) {
            message.success('Order successfully saved', 3);
            resetError(resource);
            setFormValues({});
        }
    }, [orderUpdated, resetError, setFormValues]);

    let customerOrderList = [];

    if (sortValue) {
        customerOrderList = state[resource]?.sortedCustomerOrderNewOrders;
    } else {
        customerOrderList = state[resource]?.customerOrderNewOrders;
    };

    const header = useMemo(
        () => (
        <div
            ref={listHeaderReference}
            style={{
            width: '100%',
            overflow: 'hidden',
            borderRadius: '12px 12px 0 0',
            borderLeft: '1px solid #29CABA',
            }}>
            <HeaderRow resource={resource} />
        </div>
        ),
        [resource],
    );

    const getCustomerOrderListItemSize = useCallback(
        index => (customerOrderList?.[index] ? customerOrderList[index].rowState.height : defRowHeight),
        [customerOrderList],
    );

    const RenderCustomerOrderRow = useCallback(
        ({ data, index, style }) =>
        index !== undefined && data[index] ? (
            <Row
            listRef={customerOrderRef}
            data={data[index]}
            itemSize={getCustomerOrderListItemSize(index)}
            style={style}
            resource={resource}
            startOnScroll={startOnScroll}
            index={index}
            />
        ) : null,
        [getCustomerOrderListItemSize, resource],
    );

    const allCustomerOrderList = useMemo(
        () =>
        customerOrderList && (
            <AutoSizer disableWidth>
            {({ height }) => (
                <>
                <VariableSizeList
                    ref={customerOrderRef}
                    outerRef={el => {
                        listReference.current = el;
                    }}
                    style={{
                        overflowX: 'hidden',
                        overflowY: 'auto',
                        borderBottom: '1px solid #dedede',
                        borderLeft: '1px solid #dedede',
                        borderRight: '1px solid #dedede',
                        borderRadius: '0px 0px 12px 12px',
                    }}
                    itemData={customerOrderList}
                    itemCount={customerOrderList.length}
                    height={customerOrderList && (customerOrderList.length * 29) > height ? height - 1 : customerOrderList.length * 28.2}
                    itemSize={getCustomerOrderListItemSize}>
                    {RenderCustomerOrderRow}
                </VariableSizeList>
                </>
            )}
            </AutoSizer>
        ),
        [customerOrderList, getCustomerOrderListItemSize, RenderCustomerOrderRow],
    );

    const handleSelectChange = e => {
        sortBy('customerOrder', e.key);
        setSortValue(e.key);
    };
    
    const menu = (
        <Menu className='customer-order__menu' onClick={handleSelectChange}>
            <Menu.Item key="byDate">By date</Menu.Item>
            <Menu.Item key="byDirection">By direction</Menu.Item>
        </Menu>
    );
    

    const content = (
        <>
        <div className='customer-order__upper-header'>
            <div className='customer-order__upper-header__left'>
            <div className='customer-order__upper-header__sort'>
                <div className='sort_icon'></div>
                <Dropdown className='customer-order__dropdown' overlay={menu} placement="bottomLeft">
                    <span onClick={(e) => e.preventDefault()}>
                    <Space>Sort</Space>
                    </span>
                </Dropdown>
            </div>
            <separator className={'customerOrder__separator'} vertical=''></separator>
            <div className='customer-order__upper-header__orders-count'>
                Upcoming Orders: <span>{totalOrders}</span>
            </div>
            </div>
        </div>
        <div className='headerContainer'>{header}</div>
        <div style={{ flexGrow: 1, width: smallScreen ? '100%' : width }}>{allCustomerOrderList}</div>
        <div
            style={{
                width: 'calc(100% + 8px)',
                overflowX: 'scroll',
                flexShrink: 0,
                marginBottom: '3px',
                position: 'absolute',
                bottom: '0px',
                height: '7px',
            }}
            ref={mainScroll}
            onScroll={e => onScrollX(e)}>
            <div
            className='table-body-cell__customer-dashboard'
            style={{
                maxWidth: `${width}px`,
                width: `${width}px`,
                minWidth: `${width}px`,
            }}
            />
        </div>
        </>
    );

    return <>{loading ? <Loading /> : content}</>;
    };

const mapStateToProps = state => ({
    state: state,
    customerOrder: state.customerOrderNewOrders,
    loading: state.customerOrderNewOrders.loading,
    totalOrders: state.customerOrderNewOrders.totalOrders,
    orderUpdated: state.customerOrderNewOrders.orderUpdated,
});

const mapDispatchToProps = dispatch => ({
    customerOrderNewOrders: (resource, endPoint) => dispatch(customerOrderNewOrders(resource, endPoint)),
    sortBy: (resource, sortValue) => dispatch(sortBy(resource, sortValue)),
    resetError: resource => dispatch(resetError(resource)),
    setFormValues: (resource, formValues) => dispatch(setFormValues(resource, formValues)),  
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerOrderNewOrdersSpreadsheet);
