import { useSelector } from "react-redux";

export const AuthSelectors = () => {
  const state = useSelector(state => state);
  const googleApiKey = useSelector(state => state.auth.googleApiKey);

  return { 
    state, 
    googleApiKey,
  };
};