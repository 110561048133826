import { CUSTOMER_ORDERS_BUILD as actionTypes } from '../../actions/customerOrderAdmin/customerOrderAdminActionTypes';
import * as customerOrder from './customerOrderAdminReducers';

const defaultState = {
  headers: [
    {
      key: 'customer',
      title: 'CUSTOMER',
      width: 123,
    },
    {
      key: 'readyDate',
      title: 'READY DATE',
      width: 123,
    },
    {
      key: 'dueDate',
      title: 'DUE DATE',
      width: 123,
    },
    {
      key: 'pickLocation',
      title: 'PICK LOCATION',
      width: 123,
    },
    {
      key: 'dropLocation',
      title: 'DELIVERY',
      width: 123,
    },
    {
      key: 'poNumber',
      title: 'PO#',
      width: 123,
    },
    {
      key: 'soNumber',
      title: 'SO#',
      width: 123,
    },
    {
      key: 'palletCount',
      title: 'PAL',
      width: 123,
    },
    {
      key: 'space',
      title: 'SPACE',
      width: 123,
    },
    {
      key: 'lbs',
      title: 'LBS',
      width: 123,
    },
    {
      key: 'temperature',
      title: 'TEMP',
      width: 123,
    },
    // {
    //   key: 'freight',
    //   title: 'FREIGHT VALUE',
    //   width: 123,
    // },
    {
      key: 'orderCycle',
      title: 'ORDER CYCLE',
      width: 123,
    },
  ],
  loading: false,
  generateCustomerOrderLoading: false,
  hasError: false,
  messageText: '',
  total: 0,
  existing: 0,
  customerOrderAdmin: null,
  orderGenerated: false,
  orderRejected: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.CUSTOMER_ORDER_ADMIN_LOADING:
      return customerOrder.setInLoading(state);
    case actionTypes.SET_HEADER_WIDTH_ORDER_ADMIN:
      return customerOrder.setHeaderWidth(state, action.data.ind, action.data.width);
    case actionTypes.ERROR:
      return customerOrder.setError(state, action.data);
    case actionTypes.RESET_ERROR:
      return customerOrder.resetError(state);
    case actionTypes.GET_CUSTOMER_ORDER_ADMIN:
      return customerOrder.customerOrderAdmin(state, action.data);
    case actionTypes.SORT_ORDERS_ADMIN:
      return customerOrder.sortBy(state, action.data);
    case actionTypes.FILTER_BY_CUSTOMER:
      return customerOrder.filterByCustomer(state, action.data);
    case actionTypes.GENERATE_CUSTOMER_ORDER_LOADING:
      return customerOrder.generateCustomerOrderLoading(state);
    case actionTypes.REJECT_CUSTOMER_ORDER_LOADING:
      return customerOrder.rejectCustomerOrderLoading(state);
    case actionTypes.GENERATE_CUSTOMER_ORDER:
      return customerOrder.generateCustomerOrder(state, action.data);
    case actionTypes.REJECT_CUSTOMER_ORDER:
      return customerOrder.rejectCustomerOrder(state, action.data);
    case actionTypes.NEW_REQUEST_ORDERS_COUNT:
      console.log(action)
      return customerOrder.newRequestOrdersCount(state, action.data);
    default:
      return state;
  }
};
